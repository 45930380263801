.left-navigation {
	display: flex;
	flex-direction: column;
	text-align: center;
	position: relative;
	background-image: linear-gradient(353deg, var(--green-1), var(--blue-1));
	padding: var(--spacing-l) 0;
}

.left-navigation li {
	margin-bottom: var(--spacing-xl);
	padding: var(--spacing-s);
	user-select: none;
	text-align: left;
}

.left-navigation li a.active i .left-navigation li a:hover i {
	color: var(--yellow-1);
	border-radius: 3px;
}

.left-navigation li a {
	color: var(--gray-5);
	display: flex;
	flex-direction: column;
	text-align: center;
	text-decoration: none;
	align-items: center;
}

.left-navigation li a img,
.left-navigation li img {
	height: 32px;
}

.left-navigation .nav-link-wrapper {
	background: var(--dark-theme-gray-2);
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	margin-bottom: 5px;
}

.left-navigation li a.active .nav-link-wrapper,
.left-navigation .nav-link-wrapper.active {
	background: var(--blue-2);
}

.left-navigation li a.active .nav-link-wrapper.violet-1,
.left-navigation .nav-link-wrapper.active.violet-1 {
	background: var(--violet-1);
}

.left-navigation li a.active .nav-link-wrapper.violet-2,
.left-navigation .nav-link-wrapper.active.violet-2 {
	background: var(--violet-2);
}

.left-navigation li a.active .nav-link-wrapper.orange-1,
.left-navigation .nav-link-wrapper.active.orange-1 {
	background: var(--orange-1);
}

.left-navigation li i {
	color: var(--blue-2);
	margin-bottom: var(--spacing-s);
	font-size: 32px;
}

.left-navigation li a.active i,
.left-navigation li a .nav-link-wrapper.active i {
	color: var(--gray-0);
}

.left-navigation li.logo {
	padding: 0;
	pointer-events: none;
}

.left-navigation li.logo a img {
	border-radius: 3px;
	max-width: 45px;
}

.left-navigation li.logo a {
	flex-direction: row;
	justify-content: center;
}

.left-navigation li span {
	color: var(--gray-0);
	text-decoration: none;
	font-size: 12px;
}

.left-navigation.expanded li a {
	justify-content: flex-start;
}

.left-navigation.expanded li i {
	margin-right: var(--spacing-m);
}

.left-navigation.expanded li.logo a img {
	max-width: 140px;
}

.left-navigation li.bottom-position {
	margin-top: auto;
}

.left-navigation li:last-of-type {
	margin-bottom: 0;
}

.left-navigation li .violet-1 i {
	color: var(--violet-1);
}

.left-navigation li .violet-2 i {
	color: var(--violet-2);
}

.left-navigation li .orange-1 i {
	color: var(--orange-1);
}

.navigation ul {
	display: flex;
}

.header {
	display: flex;
	align-items: flex-start;
	padding: var(--spacing-xl);
	position: relative;
	margin-bottom: var(--spacing-xxl);
}

.header .logo {
	font-size: 0;
}

.header .navigation .dropdown {
	padding: 0;
}

.header > div:first-of-type a {
	display: flex;
}

.header > div:first-of-type a img {
	padding: 3px;
}

.header > div:last-of-type {
	margin-left: auto;
	padding-top: 7px;
}

.dropdown {
	display: inline-flex;
	align-items: center;
	position: relative;
	padding: var(--spacing-s);
	border-radius: var(--border-radius);
}

.dropdown.dropdown--reverse a {
	flex-direction: row-reverse;
}

.dropdown > a {
	text-decoration: none;
	color: var(--gray-5);
	display: flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
	user-select: none;
}

.dropdown > a i {
	margin-right: var(--spacing-m);
	color: var(--gray-5);
	font-size: 22px;
}

.dropdown > a img {
	height: 25px;
	width: 25px;
	border-radius: 100%;
	margin-right: var(--spacing-m);
}

.dropdown > a img ~ i {
	margin: 0;
}

.dropdown.bottom.dropdown--open .dropdown__items,
.dropdown.top.dropdown--open .dropdown__items {
	opacity: 1;
	transform: scale(1);
	visibility: visible;
	z-index: 99;
	padding: initial;
}

.dropdown.bottom .dropdown__items {
	position: absolute;
	top: calc(100% + 5px);
	right: 0;
	background: var(--gray-0);
	opacity: 0;
	visibility: hidden;
	transform: scale(0.95);
	transition: 0.1s;
	transform-origin: top right;
	border-radius: 6px;
}

.dropdown.top .dropdown__items {
	position: absolute;
	bottom: calc(100% + 15px);
	right: 0;
	background: var(--gray-0);
	opacity: 0;
	visibility: hidden;
	transform: scale(0.95);
	transition: 0.1s;
	transform-origin: top right;
}

.dropdown.top.precautions-dropdown .dropdown__items {
	z-index: 10000;
	background: transparent;
}

.call-view__footer .dropdown.top .dropdown__items {
	border-radius: 6px;
	overflow: hidden;
}

.list-group {
	border-radius: var(--border-radius);
	flex-direction: column;
	padding: var(--spacing-m) 0;
	position: relative;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	background: var(--gray-0);
	border-radius: 6px;
}

.list-group li {
	margin: 0;
	position: relative;
}

.list-group li:hover {
	background: var(--blue-1);
}

.list-group li:hover > a span,
.list-group li:hover > a > i {
	color: #fff;
}

.list-group li a > .list-group {
	opacity: 0;
}

.list-group li:hover a > .list-group {
	opacity: 1;
}

.list-group li a {
	display: flex;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
	color: var(--gray-5);
	font-size: 14px;
	text-decoration: none;
	padding: var(--spacing-m) var(--spacing-sssl) var(--spacing-m) var(--spacing-xl);
	width: 100%;
}

.list-group li a.red span {
	color: var(--red-1);
}

.list-group li a i {
	margin-right: var(--spacing-m);
	color: var(--gray-5);
	font-size: 18px;
}

.list-group .list-group {
	position: absolute;
	right: 100%;
	top: 0;
}

.hello-feature-block {
	padding: var(--spacing-sl) var(--spacing-xxl);
	border-radius: var(--border-radius);
	text-align: center;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	cursor: pointer;
	transition: 0.15s;
	user-select: none;
	background: var(--gray-0);
	position: relative;
}

.hello-feature-block:hover,
.hello-feature-block:active {
	background: var(--blue-2);
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	transform: translateY(-5px);
}

.hello-feature-block:active {
	transform: translateY(-2px);
}

.hello-feature-block:hover i,
.hello-feature-block:hover p {
	color: var(--gray-0);
}

.hello-feature-block i {
	margin-bottom: var(--spacing-s);
	color: var(--blue-2);
}

.hello-feature-block p {
	margin: 0;
	color: var(--gray-4);
}

.hello-feature-block .info {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 0;
}

.hello-feature-block .info i {
	font-size: 16px;
}

.hello-feature-block .info::before {
	white-space: normal;
	width: 300px;
	padding: var(--spacing-m);
}

.breadcrumb {
	display: flex;
}

.breadcrumb li,
.breadcrumb li a {
	color: var(--gray-4);
	font-size: 15px;
	display: flex;
}

.breadcrumb li:not(:last-of-type)::after {
	content: 'keyboard_arrow_right';
	font-family: 'Material Icons';
	position: relative;
	top: 3px;
	margin-right: var(--spacing-s);
}

.breadcrumb li.active a {
	color: var(--indigo-1);
}

.breadcrumb li a {
	text-decoration: none;
}

.breadcrumb li a:hover {
	text-decoration: underline;
}

.badge {
	display: inline-block;
	font-size: 12px;
	padding: calc(var(--spacing-s) / 2) var(--spacing-m) !important;
	border-radius: var(--border-radius);
}

.table .badge {
	border-radius: 25px;
}

.badge.xsmall {
	padding: 0 var(--spacing-s) !important;
	font-size: 10px;
}

.badge.green {
	background: var(--green-1);
	color: var(--gray-0);
}

.badge.gray {
	background: var(--gray-1);
	color: var(--gray-4);
}

.badge.red {
	background: rgba(225, 0, 0, 0.25);
	color: var(--gray-4);
}

.badge.darkred {
	background: var(--red-1);
	color: var(--gray-0);
	margin-top: 3px;
	margin-left: 5px;
}

div.badge > i {
	margin: 0;
	font-size: 14px;
	position: relative;
	top: 2px;
}

.tree {
	padding-left: var(--spacing-l);
	padding-right: var(--spacing-l);
	font-size: 14px;
	color: var(--gray-5);
	width: 100%;
}

.tree .tree__child {
	padding: var(--spacing-m) var(--spacing-xl);
}

.tree .tree__child ~ div:last-of-type {
	margin: 0;
}

.tree .tree__child > div {
	width: 100%;
	display: flex;
	align-items: center;
}

.tree .tree__child > div > img {
	height: 16px;
	margin-right: 5px;
}

.tree.search-mode .tree__child.hidden + div {
	display: none;
}

.tree .tree__child:hover i:not(.hide) {
	opacity: 1;
}

.tree .action {
	float: right;
	font-size: 0;
	background: none;
	border: none;
}

.tree > div,
.tree > div:last-of-type {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.tree p {
	margin: 0;
	padding: 0;
	font-size: 12px;
	color: var(--red-1);
	position: relative;
}

.tree p::first-letter {
	text-transform: uppercase;
}

.tree > div + div {
	flex-direction: column;
	align-items: flex-start !important;
}

.tree i {
	cursor: pointer;
	font-size: 18px;
	user-select: none;
	margin-right: var(--spacing-s);
	color: var(--gray-4);
}

.tree i.hide {
	opacity: 0;
}

.tree .action i {
	opacity: 0;
	transition: 0.2s;
}

.tree .link {
	flex: 1;
	text-decoration: none;
	color: var(--gray-5);
	word-break: break-word;
}

.tree .link.unassignable {
	color: var(--gray-3);
	cursor: default !important;
}

.tree .selected {
	position: relative;
}

.tree .selected::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: #edeef3;
	height: 100%;
}

.tree .selected > div {
	position: relative;
}

.tree .tree__new {
	display: flex;
	flex-direction: row;
	align-items: center !important;
}

.tree .tree__new ~ p {
	margin-top: var(--spacing-s);
}

.tree .tree__new input {
	padding: var(--spacing-s);
	width: 100%;
	border: none;
	border-bottom: 1px solid var(--gray-2);
	font-size: 14px;
}

.tree .tree__new input::placeholder {
	text-transform: capitalize;
}

.tree .tree__new input:focus {
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.tree .room-status {
	font-size: 10px;
	margin-left: var(--spacing-s);
	display: none;
}

.tree .room-status.online,
.tree .room-status.offline,
.tree .room-status.busy,
.tree .room-status.on-call {
	display: inline-block;
}

.tree .room-status.online {
	color: var(--green-1);
}

.tree .room-status.offline {
	color: var(--gray-3);
}

.tree .room-status.busy {
	color: var(--red-1);
}

.tree .room-status.on-call {
	color: var(--orange-3);
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	z-index: 100;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.modal .link {
	pointer-events: none;
}

.modal .assign-dropdown .link {
	pointer-events: initial;
}

.modal .modal__wrapper {
	transition: 0.3s;
	min-width: 580px;
	position: relative;
	background-color: #fff;
}

.modal__actions > a:nth-child(1) {
	margin-right: 10px;
}

.modal .modal__loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal.center .modal__wrapper {
	transform: translateY(100%);
	width: 500px;
}

.modal.center.show .modal__wrapper {
	transform: translateY(0);
}

.modal.right .modal__wrapper {
	transform: translateX(100%);
	width: 580px;
}

.modal.right.show .modal__wrapper {
	transform: translateX(0);
}

.modal.left {
	justify-content: flex-start;
}

.modal.left .modal__content {
	height: 100vh;
}

.modal.right {
	justify-content: flex-end;
}

.modal.right .modal__content {
	height: calc(100vh - 70px);
	overflow-y: auto;
	overflow-x: hidden;
}

.modal .modal__close {
	position: absolute;
	top: var(--spacing-l);
	right: var(--spacing-l);
}

.modal.center {
	justify-content: center;
	align-items: center;
}

.modal.center .modal__content {
	border-radius: var(--border-radius);
	text-align: left;
	max-height: 500px;
}

.modal.loading .modal__content::before {
	content: '';
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	background: var(--gray-0);
	z-index: 1;
}

.modal.loading.left .modal__content::before {
	position: absolute;
}

.modal.loading .modal__content::after {
	content: '';
	border-radius: 100%;
	margin: 2px;
	border: 3px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 25px;
	width: 25px;
	display: inline-block;
	animation: rotate 0.4s linear infinite;
	position: absolute;
	z-index: 1;
	top: calc(50% - 12px);
	left: calc(50% - 12px);
	transform: translate(-50%, -50%);
}

.modal.show {
	opacity: 1;
	visibility: visible;
}

.modal .modal__content {
	background: var(--gray-0);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	text-align: left;
	max-height: 100vh;
	overflow: auto;
}

.modal.right .modal__wrapper {
	position: relative;
	max-height: 100vh;
}

.modal form {
	padding: var(--spacing-ssl);
}

.modal .modal__actions {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: var(--spacing-l) var(--spacing-ssl);
	width: 100%;
	background: var(--gray-1);
	display: flex;
	justify-content: flex-end;
}

.modal.hide-buttons .modal__actions {
	display: none !important;
}

.modal.center .modal__content {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.modal.center .modal__actions {
	position: relative;
	display: flex;
	justify-content: flex-end;
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.small-modal {
	border-radius: var(--border-radius);
	flex-direction: column;
	padding: var(--spacing-m) 0;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	background: var(--dark-theme-gray-2);
	width: 300px;
	height: auto;
	max-height: 300px;
	position: absolute;
	border-radius: 3px;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 99;
	top: 0;
	left: 0;
}

form h3 {
	margin: 0 0 var(--spacing-sl) 0;
}

form h3:empty {
	margin: 0;
}

.circle-loader {
	border-radius: 100%;
	margin: 2px;
	border: 3px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 25px;
	width: 25px;
	display: inline-block;
	animation: rotate 0.4s linear infinite;
}

.circle-loader.white {
	border: 2px solid #ffffff;
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 15px;
	width: 15px;
}

.skeleton-loader {
	width: 100%;
}

.skeleton-loader > div {
	padding: var(--spacing-m);
	border-radius: var(--border-radius);
	background: linear-gradient(90deg, var(--gray-1), var(--gray-2));
	margin-bottom: var(--spacing-l);
	animation: spotlight 1.5s ease infinite;
	background-size: 200% 200%;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.skeleton-loader > div:nth-child(3n) {
	width: 80%;
}

.skeleton-loader > div:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
	animation: move-light 2s ease-in-out infinite;
}

@keyframes move-light {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(100%);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.input {
	margin-bottom: var(--spacing-sl);
	position: relative;
}

.input.error input,
.input.error select {
	border-bottom: solid 1px var(--red-1);
}

.input.error small,
small.red-error {
	color: var(--red-1);
	padding-top: var(--spacing-m);
	display: block;
}

.input textarea {
	width: 100%;
	padding: var(--spacing-l);
	border: 1px solid var(--gray-2);
}

datalist {
	width: 100%;
	border: 1px solid var(--gray-2);
}

form .input:last-of-type {
	margin: 0;
}

form .input-horizontal-collection .input:last-of-type {
	margin-bottom: var(--spacing-sl);
	margin-left: var(--spacing-m);
}

form .input-horizontal-collection .input:first-of-type {
	margin-right: var(--spacing-m);
}

.input p {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.input .label {
	font-weight: 500;
	margin-bottom: var(--spacing-m);
}

.input .label + p {
	color: var(--gray-4);
	margin-bottom: var(--spacing-m);
}

.input > .flex {
	align-items: center;
	margin-bottom: 7px;
}

.input .label-image {
	width: 18px;
	margin-right: 7px;
}

.input input,
.input select,
.modal.invite-people input,
.modal.invite-people .react-tel-input .form-control {
	padding: var(--spacing-l);
	width: 100%;
	border: none;
	border-bottom: 1px solid var(--gray-2);
	font-size: 14px;
	color: var(--gray-5);
}

.input select:invalid {
	color: var(--gray-3);
}

.input select option {
	color: var(--gray-5);
}

.input input:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.input.filled input {
	border-radius: var(--border-radius);
	background: var(--gray-1);
	border: 1px solid transparent;
}

.input.filled input:disabled {
	border: solid 1px var(--gray-2);
	background: var(--gray-1);
	color: var(--gray-5);
	background: #f0f0f0;
}

.input.filled input:focus {
	border: 1px solid var(--blue-2);
}

.filled .description {
	padding-top: 5px;
	padding-bottom: 10px;
}

.input p:empty {
	margin: 0;
}

.input small {
	color: var(--gray-4);
}

.select {
	width: 100%;
}

.table {
	background: var(--gray-0);
	border-collapse: collapse;
	border-radius: var(--border-radius);
	width: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: var(--spacing-sl);
}

.table header {
	border-bottom: 1px solid var(--gray-2);
}

.table header,
.table main {
	display: flex;
	width: 100%;
	font-size: 14px;
	align-items: center;
}

.table main > span {
	padding: var(--spacing-l);
	margin: 0;
	color: var(--gray-4);
}

.table header {
	border-bottom: 1px solid var(--gray-1);
}

.table header > div {
	flex: 1;
	color: var(--gray-3);
	font-weight: 500;
	font-size: 16px;
}

.table main > div {
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid var(--gray-2);
	color: var(--gray-5);
}

.table main {
	flex-direction: column;
}

.table main > div > div {
	flex: 1;
	word-break: break-all;
}

.table header div,
.table main > div > div {
	padding: var(--spacing-l);
}

.table .tabs {
	width: calc(100% + 30px);
	margin: -10px -15px;
	padding: 0;
}

.table .table__slot {
	padding: var(--spacing-l);
}

.dark-theme .table .table__slot {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.table .table__slot:empty {
	padding: 0;
}

.table .dropdown {
	padding: 0;
}

.table .input input {
	border-radius: 25px;
	padding: var(--spacing-m) var(--spacing-xl);
}

.tabs {
	display: flex;
	width: 100%;
	padding-right: 40px;
	border-bottom: 1px solid var(--gray-2);
	background: var(--gray-0);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs li {
	flex: 0 1 200px;
	text-align: center;
}

.tabs li:hover,
.tabs li.active {
	border-bottom: 2px solid var(--blue-2);
}

.tabs li > a {
	padding: var(--spacing-l);
}

.tabs li a {
	text-decoration: none;
	color: var(--gray-5);
	text-transform: capitalize;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.tabs li a i {
	font-size: 18px;
	margin-left: var(--spacing-s);
	opacity: 0;
	transition: 0.15s;
	color: var(--blue-2);
}

.tabs li a i:hover {
	color: var(--blue-2);
}

.tabs li a:hover i {
	opacity: 1;
}

.container {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	border-radius: var(--border-radius);
	width: 15px;
	height: 15px;
	margin-left: 15px;
	background: var(--gray-2);
}

.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0;
	z-index: 1;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
}

.container input:checked ~ .checkmark {
	background-color: #2196f3;
	border-radius: var(--border-radius);
}

.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.checkbox {
	display: block;
}

.checkbox .checkbox-name {
	padding-left: 5px;
}

.input .checkbox input {
	width: auto;
}

.input > i {
	position: absolute;
	right: 0;
	top: 18px;
	right: 15px;
	color: var(--gray-4);
	font-size: 18px;
}

.table .input > i {
	top: 12px;
}

.dark-theme .input > i {
	color: var(--gray-2);
}

.container input:checked ~ .checkmark:after {
	display: block;
}

.container .checkmark:after {
	left: 6px;
	top: 2px;
	width: 2px;
	height: 7px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.description {
	padding: var(--spacing-l) 0;
}

.description li {
	color: var(--gray-5);
	margin-bottom: var(--spacing-l);
}

.description li span {
	color: var(--gray-4);
	margin-right: var(--spacing-s);
}

.button {
	-webkit-appearance: none;
	padding: var(--spacing-m) var(--spacing-xl);
	background: var(--blue-2);
	color: var(--gray-0);
	border-radius: var(--border-radius);
	text-decoration: none;
	display: inline-flex;
	border: 1px solid var(--blue-2);
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	line-height: 20px;
	position: relative;
	white-space: nowrap;
	text-align: center;
	align-items: center;
	border-radius: 25px;
}

.modal .button {
	font-size: 20px;
}

.modal .button {
	font-size: 20px;
}

.button i {
	font-size: 18px;
	user-select: none;
	margin-right: var(--spacing-s);
	position: relative;
	top: 1px;
}

.button.small {
	padding: 0 var(--spacing-m);
	font-size: 12px;
}

.button.small i {
	font-size: 12px;
}

.button.large {
	padding: calc(var(--spacing-l) / 1.3) var(--spacing-sl);
}

.button + .button {
	margin-right: var(--spacing-m);
}

.button.white {
	background: var(--gray-0);
	color: var(--blue-2);
	border: 1px solid var(--blue-2);
}

.button.red {
	background: var(--red-1);
	color: var(--gray-0);
	border: 1px solid var(--red-1);
}

.button.yellow {
	background: var(--yellow-1);
	border: 1px solid var(--yellow-1);
	color: var(--gray-0);
}

.button.transparent {
	background: transparent;
	color: var(--blue-2);
	border: none;
}

.button.block {
	width: 100%;
	text-align: center;
	justify-content: center;
}

.submit-loading {
	position: relative;
	margin: auto;
}

.button.loading::before,
.submit-loading::before,
.toggle > a.loading::before {
	content: '';
	border-radius: 100%;
	border: 2px solid var(--gray-0);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 15px;
	width: 15px;
	display: inline-block;
	-webkit-animation: rotate 0.4s linear infinite;
	animation: rotate 0.4s linear infinite;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
	margin: auto;
}

.submit-loading input,
.button.loading {
	color: transparent;
}

.button.loading {
	pointer-events: none;
	user-select: none;
}

.button:focus {
	outline: none;
}

.button.download {
	justify-content: center;
	align-self: baseline;
}

.button.download i {
	font-size: 18px;
}

.button.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.call-button-wrapper {
	display: inline-block;
	margin-right: var(--spacing-m);
}

.call-button-wrapper:last-of-type {
	margin-right: 0;
}

.call-button-wrapper.dropdown {
	padding: 0;
}

.call-button-wrapper:not(.dropdown) > span {
	display: block;
	font-size: 12px;
	margin-top: 10px;
}

.more-dropdown .call-button-wrapper > span {
	margin-top: 0;
}

.call-button-wrapper.call-footer-btn-wrapper {
	width: 100%;
	margin-right: 0;
	display: flex !important;
	justify-content: flex-start;
	padding: var(--spacing-l);
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button {
	padding: 0;
	background: none;
	box-shadow: none;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button i {
	color: var(--gray-0);
	font-size: 24px;
	margin-right: 12px;
	position: relative;
	top: 1px;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button img {
	width: 24px;
	margin-right: 13px;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button.active:hover,
.call-button-wrapper.call-footer-btn-wrapper .call-button:hover {
	background: transparent;
}

.call-button-wrapper.call-footer-btn-wrapper.active .call-button i,
.call-button-wrapper.call-footer-btn-wrapper.active > span,
.call-view .call-button-wrapper.call-footer-btn-wrapper.active .volume-dropdown i,
.call-view .call-button-wrapper.call-footer-btn-wrapper.active .volume-dropdown span {
	color: var(--gray-0);
}

.call-button-wrapper.call-footer-btn-wrapper.active {
	background: var(--blue-1);
}

.call-button-wrapper.call-footer-btn-wrapper span {
	font-size: 14px;
}

.call-button-wrapper.call-footer-btn-wrapper .switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 15px;
}

.call-button-wrapper.call-footer-btn-wrapper .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.call-button-wrapper.call-footer-btn-wrapper .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.call-button-wrapper.call-footer-btn-wrapper .slider:before {
	position: absolute;
	content: '';
	height: 11px;
	width: 11px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.call-button-wrapper.call-footer-btn-wrapper input:checked + .slider {
	background-color: var(--blue-2);
}

.call-button-wrapper.call-footer-btn-wrapper input:focus + .slider {
	box-shadow: 0 0 1px var(--blue-2);
}

.call-button-wrapper.call-footer-btn-wrapper input:checked + .slider:before {
	transform: translateX(15px);
}

/* Rounded sliders */
.call-button-wrapper.call-footer-btn-wrapper .slider.round {
	border-radius: 20px;
}

.call-button-wrapper.call-footer-btn-wrapper .slider.round:before {
	border-radius: 50%;
}

.call-button-wrapper.call-footer-btn-wrapper > span,
.tv-controls-dropdown .list-group span {
	font-size: 14px;
	text-align: left;
	width: 160px;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button .circle-loader {
	border: 3px solid var(--blue-2);
	width: 15px;
	height: 15px;
}

.call-button-wrapper.call-footer-btn-wrapper .call-button.call-button-loading {
	margin-right: 10px !important;
}

.dropdown-inner-header {
	background: var(--gray-1);
	padding: var(--spacing-l);
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-direction: row;
}

.dropdown-inner-header p {
	font-size: 14px;
	margin: 0;
	padding: 0;
}

.dropdown-inner-header i {
	font-size: 18px;
	color: var(--gray-5);
}

.call-button,
.call-dropdown-btn > a {
	padding: 13px;
	border-radius: 100%;
	background: var(--gray-5);
	color: var(--gray-0);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: inline-block;
	font-size: 0;
	user-select: none;
	cursor: pointer;
	position: relative;
}

.call-dropdown-btn > a img {
	margin-right: 0;
	width: 30px;
	height: 30px;
}

.call-button .circle-loader {
	border: 3px solid var(--gray-0);
	border-bottom-color: transparent;
	border-right-color: transparent;
	width: 20px;
	height: 20px;
}

.call-button.call-button-loading {
	pointer-events: none;
}

.call-button > img {
	max-width: 30px;
}

.call-button:hover,
.call-dropdown-btn > a:hover {
	background: var(--blue-1);
}

.call-button.tooltip-hidden::before,
.call-button.tooltip-hidden::after {
	display: none;
}

.call-button.call-button-loading {
	pointer-events: none;
}

.call-button > i {
	color: var(--gray-0);
	font-size: 30px;
}

.call-button.active,
.call-dropdown-btn.dropdown--open > a {
	background: var(--blue-1);
}

.call-button.active > i {
	color: var(-gray-0);
}

.call-button.active:hover {
	background: var(--gray-5);
}

.call-button.active:hover > i {
	color: var(--gray-0);
}

.call-button.end {
	background: var(--red-1);
	border-radius: 30px;
}

.call-button.end:hover {
	background: var(--gray-5);
}

.call-button.end i {
	color: var(--gray-0);
}

.call-button.disabled,
.list-group li.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.call-button > .list-group,
.call-button + .call-button-dropdown {
	position: absolute;
	bottom: calc(100% + 10px);
	right: 0;
}

.call-button .circle-loader {
	border: 3px solid var(--gray-0);
	border-bottom-color: transparent;
	border-right-color: transparent;
	width: 20px;
	height: 20px;
}

.call-dropdown-btn .dropdown__items {
	width: 250px;
}

.dropdown.call-dropdown-btn .dropdown__items:after {
	content: '';
	position: absolute;
	top: 100%;
	right: 20px;
	margin-left: -10px;
	width: 0;
	height: 0;
	border-top: solid 10px var(--gray-0);
	border-left: solid 10px transparent;
	border-right: solid 10px transparent;
}

.call-dropdown-btn .dropdown__items > div:not(.dropdown-inner-header) {
	display: flex;
	flex-direction: column;
}

.call-dropdown-btn .dropdown__items .call-button-wrapper {
	display: block;
}

.participant {
	width: 175px;
	position: relative;
}

.participant header {
	position: absolute;
	bottom: 0;
	z-index: 1;
	bottom: 0;
	width: 100%;
}

.call-view .call-view-video.participant-screenshare {
	justify-content: flex-start;
}

.call-view .call-view-video.screenshare-full {
	justify-content: center;
}

/* will check screenshare .call-view-video.participant-screenshare video {
	max-width: calc(100vw - 180px);
	width: auto;
	object-fit: unset;
	height: auto;
	max-height: 100vh;
} */

.call-view .call-view-video.screenshare-full video {
	max-width: 100%;
}

.participant-wrapper .participant-screenshare video {
	object-fit: unset;
}

.participant header {
	padding: var(--spacing-s);
	display: flex;
	align-items: flex-end;
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	justify-content: space-between;
}

.participant header > div {
	display: flex;
	align-items: center;
}

.participant header p {
	padding-top: 0;
	margin: 0;
	font-size: 14px;
	color: var(--gray-0);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-items: center;
	background: var(--dark-theme-gray-2-08);
	padding: 0 var(--spacing-s);
	border-radius: 5px;
}

.participant header p i {
	font-size: 14px;
	margin-right: var(--spacing-s);
	position: relative;
	top: 2px;
	left: -1px;
}

.participant .mic_off-icon {
	background: var(--red-1);
	font-size: 14px;
	color: var(--gray-0);
	border-radius: 100%;
	padding: 2px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.participant .participant-volume {
	display: flex;
	align-items: center;
}

.participant .participant-volume span {
	width: 2px;
	display: inline-block;
	background: var(--blue-1);
	min-height: 2px;
	margin-right: 3px;
	transition: 0.15s;
	border-radius: 4px;
}

.grid-view .participant-volume {
	margin-right: var(--spacing-s);
}

.grid-view .show-audio-off {
	display: none;
}

.grid-view .participant .mic_off-icon {
	right: auto;
	padding: 4px;
	font-size: 16px;
}

.participant header i {
	margin: 0;
}

.participant .dropdown > a > i {
	color: var(--gray-0);
}

.participant .dropdown {
	padding: 0;
}

.participant .dropdown > a {
	background: var(--dark-theme-gray-2-08);
	padding: 0;
	border-radius: 5px;
}

.participant main {
	position: relative;
	display: inline-block;
	height: 100px;
	width: 100%;
	background: #414b58;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.participant main img {
	width: 60px;
	border-radius: 100%;
}

.participant main > div {
	font-size: 0;
	border-radius: 100%;
	transition: 0.15s;
}

.participant-wrapper {
	position: relative;
}

.participants-number {
	width: 75px;
	height: 100%;
	background: var(--dark-theme-gray-2);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.participants-number > p {
	padding: 0;
	margin: 0;
}

.participant-wrapper small:empty {
	display: none;
}

.participant-wrapper small {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	z-index: 1;
	right: var(--spacing-m);
	color: var(--gray-0);
	font-size: 12px;
	background: var(--gray-5);
	width: 100%;
	display: block;
	text-align: center;
	padding: var(--spacing-s);
	display: none;
}

.participant-wrapper small:empty {
	display: none;
}

.empty-state {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: var(--spacing-xxl);
	text-align: center;
	width: 350px;
	align-items: center;
}

.empty-state h1 {
	font-size: 24px;
	font-weight: 300;
	padding: var(--spacing-xxl) 0;
}

.label-box {
	display: flex;
	flex-direction: column;
	color: var(--gray-4);
	background: var(--gray-1);
	padding: var(--spacing-m);
}

.label-box,
.label-box i {
	font-size: 16px;
}

.label-box > div {
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing-m);
}

.label-box > div:last-of-type {
	margin: 0;
}

.label-box > div:nth-child(2) {
	margin-left: var(--spacing-xxl);
}

.label-box > div:nth-child(3) {
	margin-left: var(--spacing-sssl);
}

.label-box > div:nth-child(4) {
	margin-left: calc(var(--spacing-sssl) * 1.5);
}

.label-box i {
	color: var(--gray-4);
	margin-right: var(--spacing-s);
}

.pagination-container {
	text-align: right;
	padding-top: var(--spacing-l);
}

.pagination-container .pagination {
	width: 350px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: var(--spacing-l);
}

.pagination-container .pagination > * {
	display: inline-block;
	padding-left: var(--spacing-xl);
}

.pagination-container .pagination span {
	font-size: 14px;
}

.pagination-container .pagination .pagination-controls button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding-top: var(--spacing-s);
}

.pagination-container .pagination .pagination-controls button i {
	font-size: 18px;
	color: var(--gray-4);
}

.pagination-container .custom-select__control {
	width: 60px;
}

.assign-dropdown {
	width: 300px;
}

.dark-theme .assign-dropdown .tree {
	padding: 0;
}

.dark-theme .assign-dropdown .tree i,
.dark-theme .assign-dropdown .tree a {
	color: var(--gray-0);
}

/* custom select*/

.custom-select__control {
	width: 100%;
	font-size: 14px;
	cursor: pointer !important;
	border: 1px solid var(--gray-2);
	border-radius: 3px;
}

.custom-select__control .custom-select__placeholder {
	color: var(--gray-5);
}

.custom-select__indicator-separator {
	display: none;
}

.custom-select__menu-list > div {
	font-size: 14px;
	padding-top: 7px;
	padding-bottom: 7px;
}

/*form custom select*/

.error .form-custom-select__control {
	border-color: var(--red-1) !important;
}

.form-custom-select__control {
	padding: var(--spacing-m);
	width: 100%;
	font-size: 14px;
	color: var(--gray-5);
	border-style: none !important;
	border-radius: 0px !important;
	border-bottom-style: solid !important;
	border-color: var(--gray-2) !important;
}

.form-custom-select__control .custom-select__placeholder {
	color: var(--gray-5);
}

.form-custom-select__indicator-separator {
	display: none;
}

.form-custom-select__menu-list > div {
	font-size: 14px;
	padding-top: 7px;
	padding-bottom: 7px;
}

[data-tooltip] {
	position: relative;
}

[data-tooltip]::before,
[data-tooltip]::after {
	opacity: 0;
	visibility: hidden;
	transition: 0.1s;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
	opacity: 1;
	visibility: visible;
}

[data-delay]:hover::before,
[data-delay]:hover::after {
	transition-delay: 0.7s;
}

[data-tooltip]::before {
	content: attr(data-tooltip);
	position: absolute;
	padding: 5px 10px;
	background: var(--gray-5);
	color: var(--gray-0);
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
	left: calc(100% + 4px);
	font-size: 12px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius);
	font-weight: 500;
	max-width: 300px;
	text-transform: none;
	font-family: var(--font-family);
}

[data-tooltip]::after {
	content: '';
	background: var(--gray-5);
	padding: 4px;
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
}

[data-position='bottom']::before {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	z-index: 1;
}

[data-position='bottom']::after {
	top: calc(100% - 3px);
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
	position: absolute;
	z-index: 1;
}

[data-position='top']::before {
	bottom: calc(100% + 6px);
	top: auto;
	left: 50%;
	transform: translateX(-50%);
}

[data-position='top']::after {
	bottom: calc(100% + 3px);
	top: auto;
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
}

[data-position='left']::before {
	bottom: auto;
	top: 50%;
	right: calc(100% + 4px);
	left: auto;
	transform: translateY(-50%);
}

[data-position='left']::after {
	bottom: auto;
	top: 50%;
	left: auto;
	transform: translateY(-50%) rotate(45deg);
	right: 100%;
}

.alert {
	width: 100%;
	padding: var(--spacing-m);
	margin: var(--spacing-m) 0;
	font-size: 14px;
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: row;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.alert span {
	flex: 1;
}

.alert .close-icon {
	cursor: pointer;
}

.alert.success {
	background-color: var(--green-3);
	color: var(--gray-4);
}

.alert.success span {
	color: var(--gray-4);
}

.alert.error {
	background-color: var(--pink-1);
	color: var(--red-1);
}

.alert.error span {
	color: var(--gray-5);
}

.alert.neutral {
	border: none;
	background-color: var(--gray-1);
	color: var(--gray-4);
}

.alert.neutral span {
	color: var(--gray-4);
}

.alert.dark {
	border: none;
	background-color: var(--gray-5);
	color: var(--gray-0);
}

.alert.dark span {
	color: var(--gray-0);
}

.alert.show {
	opacity: 1;
	visibility: visible;
}

.alert.fixed {
	position: fixed;
	transform: translateY(100%);
	left: var(--spacing-m);
	bottom: var(--spacing-m);
	opacity: 0;
	z-index: 9999;
	width: auto;
}

.alert.fixed.show {
	animation: showAlert 3s;
}

.alert.persist,
.alert.fixed.persist {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
	right: var(--spacing-m);
	left: initial;
	bottom: var(--spacing-xxl);
}

.alert.top {
	position: fixed;
	left: 50%;
	top: 10px;
	bottom: auto;
	right: auto;
	transform: translate(-50%, 0%);
	height: auto;
	max-width: 600px;
	z-index: 9999;
}

.alert.right {
	left: initial;
	right: var(--spacing-m);
}

.view-request-history-item.alert-history .false-alert {
	color: var(--red-3);
}

.view-request-history-item p span {
	font-weight: 500;
	color: var(--gray-5);
	margin: 0;
}

.view-request-history-item.alert-history span {
	padding: 0 var(--spacing-s);
}

.view-request-history-modal {
	padding: 30px;
}

.view-request-history-item.alert-history > div.flex {
	align-items: center;
	width: 100%;
}

.view-request-history-item.alert-history .alert-history-modal-icon-wrapper {
	height: var(--spacing-sl);
	width: var(--spacing-sl);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background: var(--gray-0);
	z-index: 1;
	margin-right: var(--spacing-s);
	background: #f1f1f1;
	position: relative;
	margin-bottom: var(--spacing-m);
}

.view-request-history-item.alert-history .alert-time {
	font-size: 13px;
}

.view-request-history-item.alert-history .alert-time img {
	position: relative;
	top: 2px;
}

.view-request-history-item.alert-history img {
	margin-right: var(--spacing-s);
}

.view-request-history-item.alert-history img.alert-history-modal-icon {
	width: var(--spacing-l);
	margin-right: 0;
}

.view-request-history-item.alert-history p.alert-history-desc {
	display: block;
	text-align: left;
	flex: 1 1;
	padding: 0 var(--spacing-l);
}

.modal.standard-modal-wrapper.modal-wrapper-wo-btn .modal__actions .button {
	display: none;
}

.account-settings-tabs > div.flex-align-center {
	border-bottom: 1px solid var(--gray-6);
	padding: var(--spacing-l) 0;
}

.account-settings-inner-wrapper,
.account-settings-panel-wrapper,
.measurement-units-wrapper {
	padding: 0 var(--spacing-xxl);
}

.account-settings-inner-wrapper .attestations-inputs-button,
.patient-box .attestations-inputs-button {
	color: var(--blue-2);
	background: rgba(36, 126, 249, 0.1);
	font-weight: 700;
	border: none;
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 10px;
}

.attestations-inputs-button img {
	margin-right: 5px;
}

.profile-info-image button {
	margin-top: 0;
	margin-left: var(--spacing-l);
	background: rgba(36, 126, 249, 0.1);
	color: var(--blue-2);
}

input.number-input-wo-arrows::-webkit-outer-spin-button,
input.number-input-wo-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input.number-input-wo-arrows[type='number'] {
	-moz-appearance: textfield;
}

.edit-profile-btn {
	position: absolute;
	bottom: 15px;
	z-index: 99;
	right: 49px;
}

.profile-info-image > div:first-of-type {
	width: 150px;
	height: 150px;
	width: 100px;
	height: 100px;
	border-radius: 100%;
	background: var(--gray-2);
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--gray-6);
}

@keyframes showAlert {
	5% {
		opacity: 1;
		transform: translateY(0);
	}
	90% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(100%);
	}
}

.avatar {
	border-radius: 100%;
}

.avatar.small {
	width: 32px;
	height: 32px;
}

.avatar.medium {
	width: 64px;
	height: 64px;
}

.avatar.large {
	width: 128px;
	height: 128px;
}

.toggle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgba(52, 58, 64, 0.8);
	border-radius: var(--border-radius);
	overflow: hidden;
}

.toggle > a {
	padding: var(--spacing-m) var(--spacing-l);
	font-size: 14px;
	flex: 1;
	cursor: pointer;
	text-decoration: none;
	color: var(--gray-3);
	user-select: none;
}

.toggle > a.loading {
	height: 35px;
}

.toggle.toggle-on > a:first-of-type:not(.loading),
.toggle.toggle-off > a:last-of-type:not(.loading) {
	background: var(--blue-2);
	color: var(--gray-0);
	box-shadow: none;
	border-radius: 30px;
	pointer-events: none;
}

.toggle-feed {
	position: absolute;
	text-align: center;
	border-radius: 30px;
	line-height: 15px;
	user-select: none;
	top: -45px;
	width: 200px;
}

/*************
    TILT
*************/

.tilt_container {
	width: 200px;
	display: flex;
	flex-direction: column;
	z-index: 100;
	padding: 12px 20px;
	background: rgba(52, 58, 64, 0.8);
	border-radius: 10px;
	position: relative;
}

.tilt_figure {
	display: inline-block;
	padding: 6px;
	/* background: var(--gray-2); */
	border-radius: 50%;
	/* background: url(https://solprodmediastorage.blob.core.windows.net:443/static-assets/healthcare-system/full70.svg) rgba(255, 255, 255, 0.7) no-repeat; */
	/* box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7); */
	overflow: hidden;
}

.tilt_zoom_reset {
	position: absolute;
	top: 50%;
	left: calc(50% - 17px);
	transform: translate(-50%, -50%);
}

.tilt_zoom_reset.huddle-reset {
	top: calc(50% - 7px);
}

.call-view .tilt_zoom_reset.huddle-reset {
	top: calc(50% - 8px);
}

.tilt_zoom_reset > i {
	color: var(--gray-2);
	cursor: pointer;
}

.tilt__zoom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	right: var(--spacing-xl);
	top: calc(var(--spacing-sl) + var(--spacing-s));
}

.monitoring-feeds .tilt__zoom {
	top: var(--spacing-l);
}

.tilt__zoom input,
.dark-theme .tilt__zoom input {
	background: var(--gray-2);
	transform: rotate(270deg) translate(-50%, calc(var(--spacing-sssl) + 2px));
	width: 105px;
	height: 2px;
	margin: 0;
	touch-action: none;
}

.monitoring-feeds .tilt__zoom input,
.monitoring-feeds .dark-theme .tilt__zoom input {
	width: 125px;
}

.tilt_figure .btn {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
}

.tilt__zoom--text {
	padding: 0;
	margin: 0;
	font-size: 14px;
	color: var(--gray-4);
}

.tilt__zoom--in,
.tilt__zoom--out {
	height: 100%;
	width: 35px;
	border: none;
	cursor: pointer;
}

.tilt__zoom--in i,
.tilt__zoom--out i {
	font-size: 16px;
	color: var(--gray-4);
}

.tilt__zoom--in:focus,
.tilt__zoom--out:focus {
	outline: none;
}

.tilt__zoom button {
	border-radius: 30px;
	width: 37px;
	font-size: 0;
	padding: 0;
}

figure {
	position: relative;
	width: 115px;
	height: 115px;
	border-radius: 50%;
	transform: rotate(45deg);
	margin: 0;
}

figure figcaption {
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
	z-index: 3;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

figure figcaption > i {
	color: var(--gray-2);
}

figcaption img {
	width: 100%;
	user-select: none;
}

/*************
    TILT BTN
*************/

.btn {
	width: 50px;
	height: 51px;
	position: absolute;
	transform-origin: 100% 100%;
	cursor: pointer;
}

.btn::after {
	content: '';
	position: absolute;
	z-index: 2;
	width: 100px;
	height: 100px;
	display: none;
}

.btn.btn_up::after {
	/* background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat; */
	top: -32px;
	left: -32px;
	transform: rotate(-135deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_up:hover::after {
	display: block;
}

.btn.btn_right::after {
	/* background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat; */
	top: -32px;
	left: 3px;
	transform: rotate(-45deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_right:hover::after {
	display: block;
}

.btn.btn_left::after {
	/* background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat; */
	top: 4px;
	left: -32px;
	transform: rotate(135deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_left:hover::after {
	display: block;
}

.btn.btn_down::after {
	/* background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat; */
	top: 4px;
	left: 3px;
	transform: rotate(45deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_down:hover::after {
	display: block;
}

.btn:nth-of-type(2) {
	right: 0;
}

.btn:nth-of-type(3) {
	bottom: 0;
	left: 0;
}

.btn:last-of-type {
	right: 0;
	bottom: 0;
}

.btn:before {
	content: '';
	opacity: 0.6;
	position: absolute;
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.64 5.2'><defs><style>.cls-1%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;%7D</style></defs><title>arrow</title><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path class='cls-1' d='M14.89,4.45,8.9,1A2.22,2.22,0,0,0,6.75,1l-6,3.42'/></g></g></svg>");
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
}

.btn:hover:before {
	opacity: 1;
}

.btn:first-of-type:before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.btn:nth-of-type(2):before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
}

.btn:nth-of-type(3):before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-135deg);
}

.btn:last-of-type:before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(135deg);
}

.tilt__icons {
	fill: gray;
}

.reactLink {
	text-decoration: none;
}

/*
    Incoming call Patient
*/

.incoming-calls {
	width: 100vw;
	background: linear-gradient(135deg, rgba(89, 213, 89, 0.9) 0%, rgba(36, 171, 253, 0.9) 100%);
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
}

.incoming-call-img {
	border-radius: 50%;
	animation: pulse 2s infinite;
}

.incoming-call-text {
	color: white;
	font-size: 24px;
}

div.shared-volume-range {
	width: 100%;
	padding-left: var(--spacing-l);
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-m);
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	background: var(--dark-theme-gray-4);
}

span.volume-value {
	margin-left: 12px !important;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
	}
}

.stream-permissions {
	width: 100vw;
	background-color: rgba(1, 83, 182);
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
}

.stream-permissions h2,
.stream-permissions h3,
.stream-permissions p {
	color: white;
}

.stream-permissions p {
	margin: 0;
}

.stream-permissions h2,
.stream-permissions h3 {
	margin: 0 0 10px;
}

.users-view .assign-btn {
	background: var(--blue-2);
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 500;
	color: var(--gray-0);
	border-radius: var(--border-radius);
	cursor: pointer;
	display: inline-block;
	word-break: normal;
}

.users-view .assign-btn.unassigned span {
	display: flex;
	align-items: center;
	white-space: nowrap;
	font-size: 12px;
	text-decoration: none;
	font-weight: bold;
}

.users-view .assign-btn.unassigned span i {
	padding-right: 10px;
	padding-right: 5px;
	font-size: 22px;
}

.configurations-history {
	margin-bottom: 10px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

/* Remove region styling*/

.add-remove-region {
	position: relative;
}

.add-remove-region i {
	position: absolute;
	right: 15px;
	top: 15px;
	color: var(--gray-4);
	cursor: pointer;
}

.create-hs input + small,
.create-hs .add-remove-region ~ p {
	color: var(--red-1);
	font-size: 14px;
	font-weight: normal;
}

.create-hs-success-icon {
	padding: 20px;
	color: var(--gray-0);
	font-size: 50px;
	border-radius: 100%;
	background: var(--green-1);
}

.create-hs-success {
	display: flex;
	flex-direction: column;
	height: 100vh;
	align-items: center;
	justify-content: center;
}

.create-hs-success > * {
	margin-bottom: var(--spacing-sssl);
}

.call-logs-view .call-properties span {
	display: block;
	width: 100%;
}

.profile-picture {
	height: 30px;
	object-fit: cover;
	width: 30px;
	border-radius: 100%;
	margin-right: var(--spacing-l);
}

.call-correlation-info.out-of-call {
	bottom: 10px;
}

.call-correlation-info > span {
	display: block;
	color: white;
}

.call-correlation-info span.correlation-id {
	color: white;
}

.call-correlation-info span .copy-to-clipboard {
	cursor: pointer;
	top: 0;
}

.call-correlation-info span .copy-to-clipboard i {
	color: #fff;
	font-size: 14px;
	margin-left: 5px;
}

.build-number {
	position: fixed;
	bottom: 0;
	right: 0;
	background: white;
	padding: 4px 8px;
	font-size: 13px;
	box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	color: #333;
}

@media (max-height: 500px) {
	.modal.center .modal__content {
		max-height: 100vh;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default !important;
}

.mr-20 {
	margin-right: 20px;
}

.blank-button {
	padding: 0;
	background: none;
	border: none;
}

.active-configuration {
	background-color: #d0f0c0;
}

.configurations-back-btn {
	display: flex;
	cursor: pointer;
	margin-bottom: 15px;
}

.chat-list-item {
	transition: all 0.15s linear;
	cursor: pointer;
	align-items: center;
	padding: var(--spacing-m);
	border-bottom: 1px solid var(--gray-1);
	margin-bottom: -1px;
	position: relative;
}
.chat-list-img {
	overflow: hidden;
}
.chat-list-item .chat-list-img {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	flex-basis: 35px;
	position: relative;
}
.chat-list-item .chat-list-img img {
	position: relative;
}
.chat-list-item .chat-list-name {
	padding: 0 var(--spacing-l);
	flex-basis: calc(100% - 40px);
}
.chat-list-item .chat-list-name:not(.header-search-name) {
	align-items: center;
}
.chat-list-item .chat-list-name.header-search-name {
	align-items: flex-start;
	flex-direction: column;
	margin-top: -5px;
}
.chat-list-item .chat-list-name.header-search-name span {
	font-size: 12px;
	color: var(--gray-4);
}
.chat-list-item .chat-list-name {
	flex-basis: calc(100% - 50px);
	max-width: 100%;
	overflow: hidden;
}
.chat-list-item .chat-list-name p {
	margin: 0;
	padding: 0;
	font-weight: 500;
	font-size: 14px;
}
.chat-list-item .chat-list-name p,
.chat-list-item .chat-list-name span {
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.chat-list-item .chat-list-name p.header-search-primary span {
	font-size: 14px;
	font-weight: 500;
}
.chat-item-title {
	padding: var(--spacing-xl);
	text-align: left;
}
.chat-item-title span {
	color: var(--blue-2);
	font-size: 14px;
}
.header-searchbar-results {
	min-height: 60px;
	max-height: 160px;
	background: var(--gray-0);
	width: 100%;
	z-index: 100;
	border-radius: var(--border-radius-lg);
	box-shadow: 0 0 10px -2px var(--gray-4);
	overflow: auto;
	position: absolute;
	top: 80px;
}
.header-searchbar-results > div {
	max-height: 430px;
	overflow: auto;
}
.header-searchbar-results .chat-list-item .chat-list-name.header-search-name span {
	color: var(--gray-3);
	font-size: 12px;
}
.header-searchbar-results .chat-list-item .chat-list-name p {
	font-weight: 700;
	color: var(--gray-5);
}
.header-searchbar-results a {
	text-decoration: none;
}

.outline-none {
	outline: none;
}

.edit-avatar-wrapper {
	position: relative;
	width: 64px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
}

.edit-avatar-inner {
	position: absolute;
	z-index: 100;
	width: 64px;
	height: 64px;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	border-radius: 100%;
	background: rgba(255, 255, 255, 0.8);
	display: none;
	align-items: center;
	justify-content: center;
}

.edit-avatar-inner i {
	color: var(--gray-4);
	font-size: 18px;
}

.edit-avatar-wrapper:hover > .edit-avatar-inner {
	display: flex;
}

.crop-modal .modal__content > div {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.crop-modal {
	pointer-events: none;
}

.crop-modal .modal__wrapper {
	pointer-events: auto;
}

.margin-lr-auto {
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (min-width: 991px) {
	.tilt_zoom_reset.huddle-reset.huddle-reset-mobile {
		top: calc(50% - 20px);
	}
}

@media only screen and (min-width: 768px) {
	.call-button-wrapper.call-footer-mobile-btn,
	.call-footer-mobile-btn {
		display: none !important;
	}

	.call-footer-desktop-btn {
		display: inline-block !important;
	}

	.call-footer-btn-wrapper.call-footer-desktop-btn {
		display: flex !important;
	}

	.collapse-second-column.organization-hamburger-menu {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.call-footer-mobile-btn {
		display: inline-block !important;
	}

	.call-footer-btn-wrapper.call-footer-mobile-btn {
		display: flex !important;
	}

	.call-button-wrapper.call-footer-desktop-btn,
	.call-footer-desktop-btn {
		display: none !important;
	}

	.alert.top {
		max-width: calc(100% - 30px);
	}

	.patient-view-control {
		position: fixed;
		top: calc(100vh - 200px);
		left: 0 !important;
		width: 100%;
		z-index: 9999;
	}

	.tabs-wrapper,
	.table {
		max-width: 100%;
		overflow-x: auto;
		padding-right: 0;
	}

	.table header div,
	.table main > div > div {
		min-width: 150px;
	}

	.dark-theme .table main > div:nth-child(odd) {
		background: initial !important;
	}
}

@media only screen and (min-width: 600px) {
	.call-button-show-mobile {
		display: none !important;
	}
}

@media only screen and (max-width: 599px) {
	.call-button-remove-mobile {
		display: none !important;
	}

	.call-button-show-mobile {
		display: flex !important;
	}
}

.media-controls {
	display: flex;
	align-items: flex-end;
	margin: 10px 0px 15px 10px;
}

.media-controls > div {
	margin-right: 3px;
	background-color: var(--blue-2);
	width: 5px;
}

.media-controls > .button.small {
	padding: 0 12px;
}

.display-flex {
	display: flex;
}

.call-maximize {
	position: absolute;
	top: 10px;
	right: 10px;
	border: none;
	border-radius: 100%;
	background: var(--dark-theme-gray-4);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	display: flex;
	height: 30px;
	width: 30px;
	cursor: pointer;
	color: var(--gray-0);
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.3s ease-in;
}

.call-maximize > i {
	font-size: 15px;
}

.call-view:hover .call-maximize {
	opacity: 1;
}

.signout-frame {
	visibility: hidden;
}

.select-wrapper select {
	-webkit-appearance: none;
	appearance: none;
	padding-right: 35px;
}

.select-wrapper::after {
	content: '';
	background: url(https://static.solaborate.com/americanwell/amwell-icons/arrow-white.svg) no-repeat center center;
	background-size: 12px;
	width: 12px;
	height: 12px;
	bottom: 18px;
	right: 15px;
	position: absolute;
	pointer-events: none;
}

.select-wrapper-dialOut::after {
	content: '';
	background: url(https://static.solaborate.com/americanwell/amwell-icons/arrow-white.svg) no-repeat center center;
	background-size: 12px;
	width: 12px;
	height: 12px;
	top: 20px;
	right: 15px;
	position: absolute;
	pointer-events: none;
}

.select-wrapper.select-has-errors::after {
	bottom: 40px;
}

.select-wrapper.input.error::after {
	bottom: 45px;
}

.invite-participants > p {
	font-weight: 400;
	color: var(--gray-3) !important;
}

.remove-number-button {
	background: transparent;
	color: white;
	border: none;
	margin-left: 16px;
}

.remove-number-button:disabled {
	opacity: 0.5;
}

.dial-out-button:disabled {
	opacity: 0.7;
}
