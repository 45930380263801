html,
body {
	overflow: hidden;
}

.hello-list,
.main-view {
	height: 100vh;
	overflow: auto;
}

.app-wrapper {
	display: flex;
	flex-direction: column;
}

.app-wrapper > div {
	background: var(--gray-0);
}

.collapsed-second-column {
	grid-template-columns: 0 3fr !important;
}

.collapsed-second-column aside {
	padding: 0;
}

.collapse-second-column {
	position: absolute;
	top: var(--spacing-sl);
	left: var(--spacing-l);
	background: var(--gray-0);
	padding: var(--spacing-s) calc(var(--spacing-s) / 2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	font-size: 0;
	border-radius: var(--border-radius);
	z-index: 1;
	cursor: pointer;
}

.hello-list .collapse-second-column,
.dark-theme .hello-list .collapse-second-column,
.collapse-second-column.organization-hamburger-menu,
.dark-theme .collapse-second-column.organization-hamburger-menu {
	left: auto;
	right: var(--spacing-xxl);
	top: calc(var(--spacing-sl) + 2px);
	padding: 0;
	background: none;
}

.collapse-second-column.organization-hamburger-menu,
.dark-theme .collapse-second-column.organization-hamburger-menu {
	top: var(--spacing-xl);
}

.dark-theme .collapse-second-column.organization-hamburger-menu i {
	color: var(--gray-0);
}

.hello-list .collapse-second-column i {
	font-size: 28px;
}

.dark-theme .hello-list .collapse-second-column i {
	color: var(--gray-0);
}

.collapse-second-column i {
	color: var(--gray-4);
}

.collapsed-second-column ~ .collapse-second-column {
	left: 75px;
}

.full-width {
	width: 100% !important;
}

.z-10000 {
	z-index: 10000;
}

.white-color {
	color: var(--gray-0) !important;
}

.main-view .collapse-second-column {
	display: none;
}

.main-view.monitoring-view .collapse-second-column,
.main-view.organization-view .collapse-second-column {
	display: block;
}

.main-view {
	text-align: center;
	display: flex;
	flex-direction: column;
	background: var(--gray-1);
	position: relative;
}

.main-view > section {
	display: flex;
	justify-content: center;
	height: 100%;
	padding-bottom: 55px;
	overflow: auto;
	padding: 0 var(--spacing-xl);
	overflow-x: hidden;
}

.main-view.monitoring-view > section {
	padding: 0 var(--spacing-sl);
	display: flex;
	flex-direction: column;
	justify-content: start;
}

.main-view .breadcrumb + section {
	padding-bottom: 50px;
}

.main-view .hello-device-description h2 {
	margin: 0 0 var(--spacing-s);
	font-weight: normal;
}

.main-view .hello-device-description p {
	margin: 0 0 var(--spacing-sl);
	color: var(--gray-4);
}

.main-view .hello-properties {
	display: flex;
	margin: var(--spacing-sl) 0;
	padding: 0 var(--spacing-sl);
}

.main-view .hello-properties li {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--gray-0);
	border-radius: var(--border-radius);
	padding: var(--spacing-s) 0;
	margin-right: var(--spacing-sssl);
}

.main-view .hello-properties li > img {
	height: 25px;
	margin-right: 7px;
}

.main-view .hello-properties li:last-of-type {
	margin-right: 0;
}

.main-view .hello-properties li span {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 500;
}

.main-view .hello-properties li:first-of-type i {
	font-size: 10px;
}

.main-view .hello-properties li span i {
	font-size: 18px;
	margin-right: var(--spacing-s);
}

.view-patient-block > i {
	background: rgba(89, 213, 89, 0.2);
	color: var(--green-1);
	padding: var(--spacing-l);
	border-radius: 100%;
}

.talk-patient-block > i {
	background: rgba(23, 116, 204, 0.2);
	color: var(--blue-2);
	padding: var(--spacing-l);
	border-radius: 100%;
}

.feeds-0 {
	display: none !important;
}

.hello-list {
	padding: 0 var(--spacing-xxl);
	overflow-x: hidden;
	position: relative;
}

.hello-list header {
	margin: var(--spacing-xl) 0 var(--spacing-xxl);
	display: flex;
	align-items: center;
	height: 50px;
}

.hello-list header > div:not(.header-title) {
	margin-left: auto;
	margin-right: var(--spacing-sl);
}

.hello-list header > div.header-title {
	max-width: calc(100% - 30px);
}

.hello-list header.admin-header > div.header-title {
	max-width: calc(100% - 65px);
}

.hello-list header h2 {
	font-size: 28px;
	font-weight: 600;
	margin: 0;
	padding: 0;
	line-height: 0;
	font-size: 28px;
	font-weight: 600;
	margin: 0;
	padding: 0;
	line-height: 22px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: 30px;
}

.hello-list header > div.header-title[data-tooltip]::before {
	top: 100%;
	left: 0;
	transform: none;
	max-width: 100%;
	word-break: break-word;
	white-space: unset;
}

.hello-list header > div.header-title[data-tooltip]::after {
	left: 10px;
}

.hello-list a {
	text-decoration: none;
}

.hello-list .dropdown > a i {
	margin: 0;
}

.hello-list > div > .tree {
	padding: 0;
	margin-bottom: var(--spacing-s);
}

.hello-list header > div:not(.header-title) {
	display: flex;
	align-items: center;
}

.hello-list header > div:not(.header-title) span {
	flex: 1;
}

.navigation {
	display: flex;
	align-items: center;
}

.navigation > img {
	width: 150px;
	margin-right: var(--spacing-m);
	padding-right: var(--spacing-l);
	border-right: 1px solid var(--gray-0);
}

.navigation .user {
	margin-left: var(--spacing-s);
}

.navigation .user a {
	text-align: left;
}

.navigation .user p {
	margin: 0;
	padding: 0;
	line-height: 17px;
	font-size: 13px;
}

.navigation .user p:last-of-type {
	font-size: 12px;
	color: var(--gray-4);
}

.navigation .user p:last-of-type span {
	padding: 0 var(--spacing-s);
}

.navigation .user p:last-of-type i {
	font-size: 8px;
	margin: 0;
}

.navigation > div:first-of-type {
	margin-right: var(--spacing-m);
}

.call-view {
	background: var(--gray-6);
}

.call-view > main video {
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

.call-view-video.call-view-video-multiple-participants video {
	height: calc(100vh - 140px);
}

.privacy-buttons-enabled .call-view-video.call-view-video-multiple-participants video {
	height: calc(100vh - 170px);
}

.call-view .call-view-video {
	height: 100vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.call-view .call-view-video.call-view-without-video {
	align-items: center;
	width: calc(100% - 90px);
	margin-left: 90px;
}

.call-view.patient-view .call-view-video.call-view-without-video {
	width: 100%;
	margin-left: 0;
}

.call-view-video img {
	border-radius: 50%;
	width: 100%;
	background-color: #0153b6;
	animation: pulse 2s infinite;
	user-select: none;
}

.click-to-move-icon {
	margin-left: auto;
	margin-right: var(--spacing-sl);
	color: var(--gray-2);
	font-size: 22px;
	cursor: pointer;
	position: relative;
	top: 7px;
}

.click-to-move-icon::before {
	width: 140px;
	white-space: pre-wrap;
	text-align: center;
}

.privacy-buttons-enabled.call-view > main > .alert {
	width: 550px;
	text-align: center;
}

.privacy-buttons-enabled .main-multiple-participants > .alert.top {
	top: var(--spacing-s);
	margin-top: 0;
	margin-left: 45px;
}

.profile-picture-wrapper {
	overflow: hidden;
	border-radius: 100%;
	position: relative;
	user-select: none;
}

.call-profile-picture-wrapper .profile-picture {
	width: 100%;
	height: auto;
	object-fit: none;
	border-radius: initial;
	margin-right: 0;
	animation: none;
}

.profile-picture-wrapper.large {
	width: 130px;
	height: 130px;
}

.profile-picture-wrapper.medium-to-large {
	width: 100px;
	height: 100px;
}

.profile-picture-wrapper.medium {
	width: 64px;
	height: 64px;
}

.profile-picture-wrapper.small-to-medium {
	width: 50px;
	height: 50px;
}

.profile-picture-wrapper.small {
	width: 32px;
	height: 32px;
}

.profile-picture-wrapper.large .chat-list-item-initials,
.profile-picture-wrapper.medium-to-large .chat-list-item-initials {
	font-size: 22px;
}

.profile-picture-wrapper.medium .chat-list-item-initials,
.profile-picture-wrapper.small-to-medium .chat-list-item-initials {
	font-size: 16px;
}

.profile-picture-wrapper.small .chat-list-item-initials {
	font-size: 13px;
}

.chat-list-item-initials {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	opacity: 0;
	text-transform: uppercase;
}

.chat-list-item-initials.no-profile-pic {
	opacity: 1;
}

.call-view + .build-number {
	display: none;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}
}

.call-view .call-view__footer button.disabled {
	color: var(--gray-4);
	opacity: 1;
	pointer-events: none;
}

.call-view .call-view__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: var(--spacing-l) var(--spacing-xxl);
	background: var(--gray-0);
	width: 100%;
	min-height: 80px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), hsla(0, 0%, 0%, 0));
	color: black;
	grid-template-columns: 33.33% 33.33% 33.33%;
}

.call-view .call-button,
.call-view .call-dropdown-btn a,
.call-view .nav-link-wrapper,
.guest-join button,
.call-view .button,
.reset-camera {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.call-view .call-dropdown-btn .list-group li a.active {
	background: var(--blue-2);
}

@media only screen and (min-width: 991px) {
	.privacy-buttons-enabled.call-view > main > .alert {
		margin-left: 45px;
	}

	.call-view .call-view__footer:not(.footer-grid-view),
	.call-view:not(.patient-view) .call-view__footer:not(.footer-not-expanded):not(.footer-grid-view),
	.call-view .call-view__footer.footer-not-expanded:not(.footer-grid-view) {
		width: auto;
		left: calc(50% + 45px);
		transform: translateX(-50%);
		padding: 0;
		background: none;
	}

	.call-view .call-view__footer.footer-not-expanded:not(.footer-grid-view) {
		left: calc(50% + 295px);
	}

	.call-view .call-view__footer:not(.footer-grid-view) .call-view__footer--desc h3,
	.call-view__footer.footer-not-expanded:not(.footer-grid-view) .call-view__footer--desc h3 {
		left: 50%;
		width: max-content;
	}

	.call-view__footer:not(.footer-grid-view) .call-view__footer--desc p {
		display: none;
	}

	.call-view:not(.patient-view) .call-view__footer.footer-grid-view:not(.footer-not-expanded) {
		margin-left: 90px;
		width: calc(100% - 90px);
	}

	.call-view .call-view__footer.footer-grid-view.footer-not-expanded {
		width: calc(100% - 590px);
		margin-left: 590px;
	}

	main > .call-duration {
		display: none;
		position: fixed;
		top: var(--spacing-xl);
		left: calc(50% + 45px);
		font-size: 14px;
		transform: translate(-50%);
		border-radius: 6px;
		padding: var(--spacing-s);
		background: rgba(52, 58, 64, 0.8);
		width: max-content;
	}

	main > .call-view-video-multiple-participants + .call-duration,
	.privacy-buttons-enabled main > .call-duration {
		top: 140px;
	}

	.call-view:hover main > .call-duration {
		display: flex;
	}

	main.main-not-expanded > .call-duration {
		left: calc(50% + 295px);
	}

	.call-view .call-view-video {
		position: relative;
	}

	.call-view .call-view-video:not(.call-view-without-video)::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), hsla(0, 0%, 0%, 0));
	}

	.call-view.patient-view .call-view__footer:not(.footer-grid-view) {
		left: 50%;
	}

	.call-view.patient-view main > .call-duration {
		left: 50%;
	}
}

.call-view main.main-not-expanded {
	width: calc(100% - 590px);
	margin-left: 590px;
}

.call-view__footer--desc h3 {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: var(--gray-0);
	position: fixed;
	bottom: 125px;
	left: calc(50% + 45px);
	transform: translate(-50%);
	border-radius: 6px;
	padding: var(--spacing-s);
	background: rgba(52, 58, 64, 0.8);
}

.call-view__footer--desc p {
	color: var(--gray-0);
	position: fixed;
	top: var(--spacing-xl);
	left: calc(50% + 45px);
	font-size: 14px;
	transform: translate(-50%);
	border-radius: 6px;
	padding: var(--spacing-s);
	background: rgba(52, 58, 64, 0.8);
}

.call-view.patient-view .call-view__footer--desc h3,
.call-view.patient-view .call-view__footer--desc p {
	left: 50%;
}

.call-view__footer.footer-multiple-participants .call-view__footer--desc p,
.privacy-buttons-enabled .call-view__footer:not(.footer-multiple-participants) .call-view__footer--desc p {
	top: 140px;
}

.privacy-buttons-enabled .call-view__footer.footer-multiple-participants:not(.footer-grid-view) .call-view__footer--desc p {
	top: 155px;
}

.privacy-buttons-enabled .call-view__footer.footer-multiple-participants.footer-grid-view .call-view__footer--desc p {
	top: 50px;
}

.footer-grid-view.call-view__footer .call-view__footer--desc p {
	top: var(--spacing-xl);
}

.footer-not-expanded .call-view__footer--desc p,
.footer-not-expanded .call-view__footer--desc h3 {
	left: calc(50% + 295px);
}

.call-view__footer--desc h3 span {
	cursor: pointer;
	position: relative;
	top: 2px;
}

.call-view__footer--desc h3 span i {
	font-size: 16px;
	color: var(--gray-0);
}

.call-view__footer--desc {
	font-size: 12px;
}

.call-view__footer > div:nth-child(1) h3,
.call-view__footer > div:nth-child(1) p {
	margin: 0;
}

.call-view__footer > div:nth-child(2) {
	text-align: center;
}

.call-view__footer > div:nth-child(3),
.call-view__footer > div:nth-child(4) {
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.call-view__footer > div:nth-child(2) .call-button:last-of-type,
.call-view__footer > div:nth-child(3) .call-button:last-of-type,
.call-view__footer > div:nth-child(4) .call-button:last-of-type {
	margin: 0;
}

.call-view .call-view__footer .dropdown a > i {
	margin: 0;
	color: var(--gray-0);
}

.call-view__footer a:not(.button) {
	font-size: 0;
}

.call-view aside:not(.call-left-menu) {
	position: absolute;
	top: var(--spacing-xl);
	right: var(--spacing-xl);
	display: flex;
	flex-direction: row-reverse;
}

.privacy-buttons-enabled.call-view aside.participants-multiple:not(.invite-modal-participants):not(.grid-view) {
	top: var(--spacing-sssl);
}

.call-view aside.grid-view:not(.call-left-menu) {
	top: 0;
}

.call-view aside:not(.call-left-menu):not(.grid-view) .participant main {
	overflow: hidden;
	max-width: 175px;
}

.call-view.patient-view aside > a {
	margin: 0;
}

.call-view aside:not(.call-left-menu):not(.grid-view) > section {
	padding-left: var(--spacing-m);
	display: flex;
}

.call-view aside.call-left-menu {
	position: absolute;
	display: flex;
	flex-direction: row-reverse;
	height: 100vh;
	z-index: 200;
}

.call-view aside.call-left-menu .left-navigation {
	width: 90px;
	text-align: center;
}

.call-view aside.call-left-menu .left-navigation li {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.call-view aside:not(.call-left-menu):not(.grid-view) .participant-wrapper {
	margin-left: var(--spacing-m);
}

.call-view aside:not(.call-left-menu):not(.grid-view) .participant-wrapper:not(.local-participant-wrapper):not(:nth-child(-n + 3)) {
	display: none;
}

@media only screen and (max-width: 992px) {
	.call-view aside:not(.call-left-menu):not(.grid-view) .participant-wrapper:not(.local-participant-wrapper):not(:nth-child(-n + 3)) {
		display: block;
	}

	.call-view aside:not(.call-left-menu):not(.grid-view) .participant-wrapper:not(.local-participant-wrapper):not(:nth-child(-n + 2)) {
		display: none;
	}

	.participants-number {
		width: 50px;
	}
}

.call-view.patient-view .call-stats {
	top: var(--spacing-xl);
	right: var(--spacing-xl);
	left: auto;
	background: #fff;
	padding: 4px;
	border-radius: 100%;
	font-size: 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.call-view.patient-view .call-stats > div {
	top: 0;
	bottom: auto;
}

.call-view .volume-dropdown {
	display: flex;
	align-items: center;
}

.call-view .volume-dropdown button {
	font-size: 0px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.call-view .volume-dropdown button:hover i {
	color: var(--blue-2);
}

.call-view .volume-dropdown button {
	font-size: 0px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.call-view .volume-dropdown button:hover i {
	color: var(--blue-2);
}

.call-view .volume-dropdown input {
	margin: var(--spacing-m);
	max-width: 110px;
}

.call-view .volume-dropdown i,
.call-view .volume-dropdown span {
	color: var(--gray-0);
}

.call-view .volume-dropdown i {
	cursor: pointer;
	font-size: 16px;
}

.call-view .volume-dropdown span {
	font-size: 14px;
	margin: 0 var(--spacing-s);
}

.call-view.is-screensharing .call-view-video video {
	object-fit: contain;
}

.call-view .patient-audio-call-logo {
	border-radius: 100%;
	transition: 0.15s;
}

.room {
	background: var(--dark-theme-gray-0);
	border-radius: 10px;
}

.dark-theme .room {
	background: var(--dark-theme-gray-4);
}

.room .hello-device-description {
	border-bottom: 1px solid var(--dark-theme-gray-3);
	text-align: left;
	padding: var(--spacing-sl) var(--spacing-sl) 0 var(--spacing-sl);
}

.room .hello-device-description h3 {
	padding: 0;
	margin: 0;
}

.room > div > button {
	background: var(--blue-2);
	cursor: pointer;
	padding: var(--spacing-m) var(--spacing-l);
	font-size: 20px;
	font-weight: 700;
	color: var(--gray-0);
	width: 200px;
	border-radius: 25px;
	outline: none;
	border: none;
	align-self: center;
	justify-self: center;
	margin-bottom: var(--spacing-sl);
}

.room > p,
.dark-theme .room > p,
.room > .blue > p,
.dark-theme .room > .blue > p {
	padding: 0;
	color: var(--blue-2);
}

.dark-theme .department-wrapper {
	background: var(--dark-theme-gray-4);
	border-radius: 10px;
	padding: var(--spacing-sl);
}

.department-wrapper > p {
	padding: 0;
	margin: 0;
}

.dark-theme .room {
	background: var(--dark-theme-gray-4);
}

.room .hello-device-description {
	border-bottom: 1px solid var(--dark-theme-gray-3);
	text-align: left;
	padding: var(--spacing-sl) var(--spacing-sl) 0 var(--spacing-sl);
}

.room .hello-device-description h3 {
	padding: 0;
	margin: 0;
}

.room > div > button {
	background: var(--blue-2);
	cursor: pointer;
	padding: var(--spacing-m) var(--spacing-l);
	font-size: 20px;
	font-weight: 700;
	color: var(--gray-0);
	width: 200px;
	border-radius: 25px;
	outline: none;
	border: none;
	align-self: center;
	justify-self: center;
	margin-bottom: var(--spacing-sl);
}

.room > p,
.dark-theme .room > p {
	padding: 0;
	color: var(--blue-2);
}

.dark-theme .department-wrapper {
	background: var(--dark-theme-gray-4);
	border-radius: 10px;
	padding: var(--spacing-sl);
}

.department-wrapper > p {
	padding: 0;
	margin: 0;
}

.organization {
	text-align: left;
}

.organization h2 {
	font-weight: 400;
	margin: 0;
}

.organization h3 > a {
	margin-right: var(--spacing-m);
}

.view-specialties p {
	border-bottom: 1px solid whitesmoke;
	padding-left: 10px;
	padding-bottom: 5px;
}

.users .button,
.organization .button {
	float: right;
}

.users header > div:last-of-type,
.users main > div > div:last-of-type {
	flex: 2;
}

.users header > div:last-of-type {
	text-align: center;
}

.wrapped .button {
	margin-right: var(--spacing-s);
}

.wrapped > i:first-of-type {
	background: var(--blue-2);
	margin-right: var(--spacing-s);
}

.login {
	background: var(--indigo-1);
	height: 100vh;
	overflow: auto;
	padding: 20px;
}

.login .login__wrapper > div:first-of-type {
	margin: 0 auto;
	margin-bottom: var(--spacing-xxl);
}

.login .login-form-wrapper {
	background: var(--gray-0);
	padding: var(--spacing-ssl);
	border-radius: var(--border-radius);
}

.login h2 {
	text-align: center;
	margin: 0 0 var(--spacing-l);
	padding: 0;
}

.login small {
	float: right;
	padding: var(--spacing-m) 0 var(--spacing-l);
}

.login small a {
	color: var(--blue-2);
}

.create-hs {
	overflow: auto;
	background: var(--gray-1);
}

.create-hs form {
	width: 450px;
	padding: var(--spacing-sssl);
	background: var(--gray-0);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius);
}

.create-hs .create-hs__add {
	text-align: right;
	margin-bottom: var(--spacing-m);
}

.create-hs p {
	margin-bottom: var(--spacing-m);
	font-weight: 500;
	font-size: 14px;
	padding: 0;
}

.create-hs .transparent {
	padding-right: 0;
}

.users .toggleRoomSpecialties {
	cursor: pointer;
	color: var(--blue-2);
}

.users,
.organization {
	text-align: left;
	width: 100%;
}

.users > h2 {
	margin-bottom: var(--spacing-xl);
}

.users-view h3,
.call-logs-view h3 {
	margin-bottom: var(--spacing-xl);
	text-align: left;
	width: 100%;
}

.call-logs-view .InputFromTo {
	height: 38px;
	display: flex;
	align-items: center;
	padding-right: 8px;
	padding-left: 8px;
	border: 1px solid var(--gray-2);
	border-radius: 3px;
}

.dark-theme .call-logs-view .InputFromTo {
	background: rgba(255, 255, 255, 0.1);
	border: none;
	color: white;
}

.call-logs-view .table__slot .material-icons-outlined {
	position: relative;
	right: 6px;
}

.modal form > .hs-list:first-of-type a,
.modal form > .hs-list a.delete-button {
	position: relative;
	top: 5px;
}

.hs-list,
.hs-list ul {
	display: flex;
}
.hs-list span {
	cursor: pointer;
}

.hs-list {
	align-items: center;
	margin-bottom: var(--spacing-s);
	position: relative;
}

.hs-list img {
	max-height: 80px;
}
.hs-list .clearText {
	cursor: pointer;
}
.hs-list .circle-loader {
	position: absolute;
	z-index: 1;
	right: 45px;
	width: 15px;
	height: 15px;
	border: 2px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
}

.hs-list ul i {
	color: var(--gray-4);
	font-size: 12px;
	padding: var(--spacing-s);
	border-radius: var(--border-radius);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.hs-list ul li:first-of-type {
	margin-right: var(--spacing-s);
}

.hs-list input {
	flex: 1;
	font-size: 14px;
	margin-right: 0;
	padding: var(--spacing-m);
	border: none;
	border-bottom: 1px solid var(--gray-2);
}

.hs-list.hs-name input {
	padding-right: var(--spacing-sl);
}

.hs-list input:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.hs-list .input {
	width: 100%;
}

.hs-list .edit {
	background: var(--gray-4);
	color: #fff;
}

.hs-list .delete {
	background: var(--red-1);
	border: 1px solid var(--red-1);
	color: var(--gray-0);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	position: absolute;
	right: 10px;
	top: 25px;
	padding: 2px;
	border-radius: 3px;
}

.hs-list .delete.error {
	top: 15px;
}

.hs-list.hs-list-no-bottom .delete {
	top: 30px;
}

.dark-theme .hs-list .delete {
	background: none;
	padding: 0;
	border: 0;
}

.hs-list + p a {
	color: var(--blue-2);
	font-size: 14px;
	text-decoration: none;
}

.hs-list + p,
.hs-list small {
	color: var(--red-1);
	font-size: 14px;
}

.edit-hs .alert {
	left: auto;
}

.edit-hs .hs-list ~ .hs-list i:not(.delete) {
	top: 10px;
}

.region-actions {
	display: flex;
	align-items: center;
}

.region-actions small {
	color: #718093;
	margin-bottom: 10px;
}

.region-actions p {
	margin-left: auto;
	font-size: 14px;
	margin-bottom: 0;
	padding: 0;
	cursor: pointer;
}
.region-actions p span,
.region-actions p a {
	color: var(--blue-2);
}

.active-badge span {
	background: var(--green-1);
	color: var(--gray-0);
	padding: calc(var(--spacing-s) / 2) var(--spacing-m);
	border-radius: var(--border-radius);
	font-size: 12px;
}

.offline-badge span {
	background: var(--gray-1);
	color: var(--gray-4);
	padding: calc(var(--spacing-s) / 2) var(--spacing-m);
	border-radius: var(--border-radius);
	font-size: 12px;
}

.boxed-icon {
	background: var(--gray-4);
	border-radius: 7px;
	font-size: 16px;
	padding: var(--spacing-s);
	cursor: pointer;
	color: var(--gray-0);
	float: right;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.boxed-icon > img {
	height: 15px;
	width: 15px;
}

.create-hospital {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: var(--spacing-m);
	background: none;
	border: none;
}

.create-hospital > div {
	font-size: 0;
}

.create-hospital span {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

aside .create-hospital span,
.create-hospital i {
	color: var(--blue-2);
}

.create-hospital p:hover {
	font-weight: 500;
}

.create-hospital i {
	font-size: 18px;
	margin-right: var(--spacing-s);
}

.auto-answer-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
}

.auto-answer-container i {
	color: var(--blue-2);
	font-size: 60px;
	cursor: pointer;
	user-select: none;
}

.auto-answer-container p {
	padding-right: 20px;
}

.wrapped {
	display: flex;
	justify-content: flex-end;
	padding: 0 !important;
}

.wrapped > i:last-of-type {
	background: var(--red-1);
}

.wrapped > div:last-of-type > i {
	background: var(--red-1);
}

.close-feed {
	display: inline-flex;
	background: var(--gray-0);
	border-radius: 30px;
	padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-s);
	font-size: 14px;
	color: var(--gray-5);
	align-items: center;
	text-decoration: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-feed i {
	margin-right: calc(var(--spacing-s) / 2);
}

.patient-view-control {
	position: fixed;
	right: var(--spacing-xl);
	bottom: var(--spacing-xl);
	z-index: 9999;
}

.monitoring-feeds .patient-view-control {
	bottom: var(--spacing-ssl);
}

.patient-view-control > i.close-ptz {
	position: absolute;
	top: 7px;
	right: 10px;
	color: var(--gray-0);
	font-size: 22px;
	cursor: pointer;
	z-index: 300;
}

.patient-view-control > i.close-ptz {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--gray-0);
	font-size: 22px;
	cursor: pointer;
}

.user-status {
	padding-top: 0;
}

.user-status li:first-of-type {
	padding: 0;
}

.notification-group {
	display: flex;
	align-items: center;
	text-align: left;
	width: 250px;
}

.notification-group img {
	margin-right: var(--spacing-m);
}

.notification-group p {
	margin: 0;
	padding: 0;
	line-height: 18px;
	color: var(--gray-5);
	white-space: normal;
}

.notification-group p small {
	color: var(--gray-4);
}

.notifications-list {
	height: 315px;
	overflow: auto;
}

.notifications-list > li:hover * {
	color: #fff;
}

.notifications-wrapper {
	position: relative;
	display: inline-block;
}

.notifications-wrapper .badge {
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 99;
}

.role-wrapper {
	display: inline-flex;
	align-items: center;
	position: relative;
	padding: var(--spacing-s);
	cursor: pointer;
}

.switch-camera,
.switch-camera-hello {
	position: absolute;
	left: 80px;
	top: var(--spacing-xl);
}

.switch-camera-hello {
	left: 160px;
}

.modal form > div > .tree {
	padding: 0;
}

.night-vision i {
	transform: rotate(180deg);
}

.monitoring-list .hide ~ .action i {
	opacity: 1;
}

.page-loader {
	position: fixed;
	left: 0;
	top: 0;
}

.registration-form .invalid-invite {
	text-align: center;
}

.registration-form .invalid-invite i {
	font-size: 60px;
	padding-bottom: 15px;
}

.div-container {
	display: flex;
	align-items: center;
}

.bold {
	font-weight: 600;
}

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:focus,
.disabled-zoom {
	opacity: 0.25;
	cursor: initial;
	pointer-events: none;
}

.disabled-zoom {
	pointer-events: none;
}

.monitoring-feeds > div {
	min-width: 0;
}

.monitoring-feeds,
.feeds-1 > div {
	height: calc(100vh - 130px);
}

.feeds-2 > div,
.feeds-3 > div,
.feeds-4 > div {
	height: calc(50vh - 70px);
}

.monitoring-feeds-1,
.monitoring-feeds-2 {
	align-content: center;
}

.feeds-2,
.feeds-3,
.feeds-4 {
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-content: center;
}

.feeds-5,
.feeds-6 {
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr) !important;
}

.feeds-5 > div,
.feeds-6 > div {
	height: calc(50vh - 70px);
}

.feeds-7,
.feeds-8,
.feeds-9 {
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr) !important;
}

.feeds-10,
.feeds-11,
.feeds-12 {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(3, 1fr) !important;
}

.feeds-7 > div,
.feeds-8 > div,
.feeds-9 > div {
	height: calc(33vh - 45px);
}

.feeds-10 > div,
.feeds-11 > div,
.feeds-12 > div {
	height: calc(25vh - 10px);
}

.feeds-13,
.feeds-14,
.feeds-15,
.feeds-16 {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(4, 1fr) !important;
}

.feeds-13 > div,
.feeds-14 > div,
.feeds-15 > div,
.feeds-16 > div {
	height: calc(20vh - 15px);
}

.feeds-10:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-11:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-12:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-13:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-14:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-15:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items,
.feeds-16:not(.zoomed-feed) > div:nth-child(4n + 1) .dropdown.precautions-dropdown .dropdown__items {
	right: auto;
	left: 0;
	background: transparent;
}

.feeds-13:not(.zoomed-feed) > div:nth-last-child(-n + 4) .dropdown.precautions-dropdown .dropdown__items,
.feeds-14:not(.zoomed-feed) > div:nth-last-child(-n + 4) .dropdown.precautions-dropdown .dropdown__items,
.feeds-15:not(.zoomed-feed) > div:nth-last-child(-n + 4) .dropdown.precautions-dropdown .dropdown__items,
.feeds-16:not(.zoomed-feed) > div:nth-last-child(-n + 4) .dropdown.precautions-dropdown .dropdown__items {
	top: auto;
	bottom: calc(100% + 15px);
}

.zoomed-feed {
	grid-template-columns: repeat(4, 1fr);
	position: relative;
}

.zoomed-feed:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) header > div,
.zoomed-feed:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) header > li.multiple-feeds-remove,
.zoomed-feed:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6):not(.feeds-7):not(.feeds-8):not(.feeds-9):not(.feeds-10)
	> div:not(.expand-feed)
	header
	> li:nth-last-child(4),
.zoomed-feed.feeds-13 > div:not(.expand-feed) header > li:nth-last-child(3),
.zoomed-feed.feeds-14 > div:not(.expand-feed) header > li:nth-last-child(3),
.zoomed-feed.feeds-15 > div:not(.expand-feed) header > li:nth-last-child(3),
.zoomed-feed.feeds-16 > div:not(.expand-feed) header > li:nth-last-child(3) {
	display: none;
}

.zoomed-feed .patient-view-control,
.feeds-1 .patient-view-control {
	position: absolute;
	top: 40px;
	left: 20px;
}

.zoomed-feed > div {
	height: 25vh;
}

.zoomed-feed > div.expand-feed {
	grid-column: 1/5;
	grid-row-start: 1;
	height: calc(75vh - 130px);
}

.edit-member-form .profile-picture {
	width: 64px;
	height: 64px;
}

.zoomed-feed.feeds-1 > div.expand-feed {
	height: calc(100vh - 130px);
}

.zoomed-feed.feeds-5 {
	grid-template-columns: repeat(4, 1fr);
}

.zoomed-feed.feeds-5 .expand-feed {
	grid-column: 1/5;
}

.zoomed-feed.feeds-6 {
	grid-template-columns: repeat(5, 1fr);
}

.zoomed-feed.feeds-6 .expand-feed {
	grid-column: 1/6;
}

.zoomed-feed.feeds-7 {
	grid-template-columns: repeat(6, 1fr);
}

.zoomed-feed.feeds-7 .expand-feed {
	grid-column: 1/7;
}

.zoomed-feed.feeds-8 {
	grid-template-columns: repeat(7, 1fr);
}

.zoomed-feed.feeds-8 .expand-feed {
	grid-column: 1/8;
}

.zoomed-feed.feeds-9 {
	grid-template-columns: repeat(8, 1fr);
}

.zoomed-feed.feeds-9 .expand-feed {
	grid-column: 1/9;
}

.zoomed-feed.feeds-10 {
	grid-template-columns: repeat(9, 1fr);
}

.zoomed-feed.feeds-10 .expand-feed {
	grid-column: 1/10;
}

.zoomed-feed.feeds-11 {
	grid-template-columns: repeat(10, 1fr);
}

.zoomed-feed.feeds-11 .expand-feed {
	grid-column: 1/11;
}

.zoomed-feed.feeds-12 {
	grid-template-columns: repeat(11, 1fr);
}

.zoomed-feed.feeds-12 .expand-feed {
	grid-column: 1/12;
}

.zoomed-feed.feeds-13 {
	grid-template-columns: repeat(12, 1fr);
}

.zoomed-feed.feeds-13 .expand-feed {
	grid-column: 1/13;
}

.zoomed-feed.feeds-14 {
	grid-template-columns: repeat(13, 1fr);
}

.zoomed-feed.feeds-14 .expand-feed {
	grid-column: 1/14;
}

.zoomed-feed.feeds-15 {
	grid-template-columns: repeat(14, 1fr);
}

.zoomed-feed.feeds-15 .expand-feed {
	grid-column: 1/15;
}

.zoomed-feed.feeds-16 {
	grid-template-columns: repeat(15, 1fr);
}

.zoomed-feed.feeds-16 .expand-feed {
	grid-column: 1/16;
}

.feeds-1 {
	position: relative;
}

.edit-hs .label {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: var(--spacing-m);
}

.edit-hs .hs-list,
.edit-hs .input-el {
	margin-top: var(--spacing-l);
}

.feeds-limit .modal__actions > a:first-of-type {
	margin: 0;
}

.feeds-limit .modal__actions > a:last-of-type {
	display: none;
}

.dark-theme .member-error-wrapper {
	background: var(--dark-theme-gray-3);
}

.member-error {
	text-align: center;
}

.member-error img {
	max-width: 500px;
}

.member-error .logout-link {
	cursor: pointer;
	text-decoration: underline;
}

.call-stats {
	position: absolute;
	top: 98px;
	left: 5px;
	z-index: 1;
}

.call-stats > a {
	display: inline-block;
	font-size: 0;
	cursor: pointer;
}

.call-stats > a i {
	font-size: 14px;
	border-radius: 100%;
	padding: 2px;
	color: white;
	background: var(--gray-4);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.call-stats > div {
	position: absolute;
	top: 0;
	right: calc(100% + 10px);
	background: #fff;
	width: 200px;
	border-radius: 3px;
	padding: 10px;
	opacity: 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: none;
}

.call-stats > a:hover + div {
	display: block;
	opacity: 1;
}

.call-stats > div p {
	margin: 0;
	padding: 0;
	font-size: 14px;
	color: var(--gray-5);
}

.app-version * {
	padding: 0;
	margin: 0;
	line-height: 18px;
}

.app-version button {
	width: 100%;
	background: none;
	border: none;
	text-align: left;
}

.app-version button {
	color: var(--red-1);
	cursor: pointer;
}

.app-version button i {
	font-size: 15px;
	position: relative;
	top: 2px;
	left: -1px;
}

.app-version button:hover {
	text-decoration: underline;
}

.app-version small {
	font-size: 13px;
	color: var(--gray-3);
}

.app-version span {
	width: 100%;
	float: left;
}

.reconnecting > .alert.fixed {
	left: 50%;
	transform: translateX(-50%);
	bottom: auto;
	top: -80px;
}

.reconnecting > .alert.fixed.persist {
	background: rgba(0, 0, 0, 0.5);
	opacity: 1;
	visibility: visible;
	top: 15px;
	right: auto;
	bottom: auto;
	margin: 0;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	align-items: center;
	padding: 10px 16px;
}

.reconnecting > .alert.fixed.persist span {
	color: white;
}

.reset-camera {
	cursor: pointer;
	width: 115px;
	position: relative;
	user-select: none;
	border-radius: 100%;
	opacity: 0.6;
	text-align: left;
	display: flex;
	align-items: center;
}

.reset-camera > i {
	font-size: 16px;
	margin-right: var(--spacing-s);
	color: var(--gray-1);
}

.reset-camera > span {
	font-size: 14px;
	color: var(--gray-1);
}

.reset-camera:hover {
	opacity: 1;
}

.reset-camera.disabled-reset {
	opacity: 0.5;
	pointer-events: none;
}

.disabled {
	opacity: 0.75;
	pointer-events: none;
}

.disabled-click {
	pointer-events: none;
}

.availability-dropdown .list-group li:first-of-type i {
	color: var(--green-1);
}

.availability-dropdown .list-group li:last-of-type i {
	color: var(--red-1);
}

.recover-password .link,
.validate-recover-password .link {
	cursor: pointer;
	text-decoration: underline;
}

.recover-password .warning-message,
.validate-recover-password .warning-message {
	border: solid 1px grey;
	box-shadow: none !important;
	border: 1px solid #ddd;
	background-color: #f4f4f4;
	font-size: 14px;
	color: var(--gray-5);
	padding: 20px;
	display: flex;
}

.recover-password .warning-message span.material-icons,
.validate-recover-password .warning-message span.material-icons {
	font-size: 40px;
	padding-right: 10px;
	padding-top: 6px;
}

.amwell-help {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	background: #1f252b;
}

.amwell-help header {
	padding: 36px 36px 0 36px;
	width: 100%;
	display: flex;
	align-items: center;
}

.amwell-help header img {
	width: 160px;
}

.amwell-help header img:last-of-type {
	margin-left: auto;
}

.amwell-help main {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: url(https://solprodmediastorage.blob.core.windows.net:443/static-assets/banyan/world-map-white.svg) center;
	text-align: center;
}

.amwell-help main div {
	width: 300px;
}

.amwell-help main h1 {
	font-size: 28px;
}

.amwell-help main h1,
.amwell-help main p {
	color: white;
}

.edit-sector-modal input {
	margin-bottom: var(--spacing-s);
}

.edit-sector-modal input + small {
	color: var(--red-1);
}

.edit-sector-modal input + small::first-letter {
	text-transform: uppercase;
}

.user-idle-modal {
	z-index: 200;
}
.user-idle-modal .modal__wrapper {
	border-radius: 3px;
}
.user-idle-modal .modal__content {
	padding: 15px;
}
.user-idle-modal .button {
	padding: var(--spacing-m) var(--spacing-xl);
}

.crop-modal .modal__content > div {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.crop-modal {
	pointer-events: none;
}

.crop-modal .modal__wrapper {
	pointer-events: auto;
}

.hospital-logo img {
	max-height: 80px;
}

.hospital-logo p {
	font-weight: 500;
	margin-bottom: var(--spacing-m);
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.hospital-logo input {
	padding: var(--spacing-l) 0;
}

.hospital-logo input + p {
	color: var(--gray-4);
	font-weight: normal;
	margin-bottom: var(--spacing-m);
}

.hide-col:not(.grid-view) .participant-wrapper {
	display: none;
}

.toggle-right-column {
	position: relative;
	border: none;
	border-radius: 100%;
	background: var(--gray-5);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	cursor: pointer;
	color: var(--gray-0);
	z-index: 1;
}

.configuration-modal .input small {
	color: var(--red-1);
}

.loader__container {
	position: fixed;
	top: 0;
	left: 0;
	background: var(--gray-0);
	height: 100vh;
	width: 100%;
	z-index: 99;
}

.center-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-grid;
	grid-gap: 15px;
	justify-items: center;
	align-items: center;
	transform: translate(-50%, -50%);
}

.center-loader p {
	color: var(--gray-5);
	font-size: 18px;
	text-align: center;
	padding-top: 0;
	font-weight: 400;
}

.center-loader img {
	max-width: 96px;
}

.center-loader .circle-loader {
	border: 3px solid var(--blue-1);
	border-bottom-color: transparent;
	border-right-color: transparent;
}

.loader__container.call-initiating-loader img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
}

.loader__container.call-initiating-loader span {
	width: 125px;
	height: 125px;
}

.amwell-qr {
	text-align: center;
	background-image: linear-gradient(353deg, var(--green-1), var(--blue-1));
}

.amwell-qr .label {
	text-align: left;
}

.amwell-qr h3 {
	color: var(--gray-6);
	text-align: center;
	line-height: 32px;
}

.amwell-qr input.button {
	background: var(--blue-2) !important;
}

.sign-up-flow::before {
	position: absolute;
	content: '';
	width: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(280deg, #007ac9, #007894);
	height: 180px;
}

.sign-up-flow > p {
	text-align: center;
	margin: 12px 0;
	color: #313336;
	font-size: 12px;
	margin-bottom: 64px;
	margin-top: 24px;
}

.sign-up-flow main {
	width: 468px;
	max-width: calc(100% - 30px);
	padding: 32px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 0 auto;
	margin-top: 40px;
	position: relative;
	z-index: 1;
	background: var(--gray-0);
	border-radius: 10px;
	text-align: center;
}

.sign-up-flow footer {
	background: #f3f7fc;
	color: #587a8d;
	padding: 32px;
	margin: 32px -32px -32px -32px;
}

.sign-up-flow .back-link {
	border: none;
	color: #12aefc;
	background: transparent;
	font-size: 16px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0 0;
	cursor: pointer;
}

.sign-up-flow .back-link img {
	margin-right: var(--spacing-s);
}

.sign-up-flow .button {
	background: #12aefc;
	border-radius: 4px;
	width: 304px;
	max-width: 100%;
	border: none;
	font-size: 18px;
	font-weight: 500;
}

.sign-up-flow .checkmark {
	left: -1px;
	border-radius: 0 !important;
}

.sign-up-flow main canvas {
	max-width: 100%;
	height: auto !important;
}

.sign-up-flow main h1,
.sign-up-flow main h3,
.sign-up-flow main p {
	color: #587a8d;
	margin: 0;
}

.sign-up-flow main h1 {
	font-size: 34px;
}

.sign-up-flow main h3 {
	font-size: 22px;
}

.sign-up-flow main p {
	font-size: 16px;
	margin: 20px 0 40px;
}

.sign-up-flow.step-0 small {
	font-size: 14px;
	margin: 0;
	padding: 0;
	display: block;
	color: white;
}

.sign-up-flow.step-0 small a {
	color: var(--dark-theme-blue-1);
}

.sign-up-flow.step-0 img {
	margin-bottom: 32px;
	width: 300px;
}

.sign-up-flow.step-0 p {
	margin-bottom: 46px;
}

.sign-up-flow.step-0 .button {
	margin-bottom: 32px;
}

.sign-up-flow.step-1 .container {
	background: var(--gray-0);
	border: 1px solid #d6d6d6;
	border-radius: 0;
	margin-top: 4px;
	margin-right: 10px;
	margin-left: 0;
	width: 20px;
}

.sign-up-flow.step-1 input {
	background: transparent;
	border-bottom: 1px solid #6f7780;
	font-size: 16px;
}

.sign-up-flow.step-1 input:focus {
	border: none;
	border-bottom: 1px solid #12aefc;
}

.sign-up-flow.step-1 .container + span {
	text-align: left;
	display: inline-block;
	margin-bottom: 32px;
	color: #587a8d;
}

.sign-up-flow.step-1 .checkbox-wrapper {
	display: flex;
}

.cvs-export {
	grid-column-start: 7;
}

aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper {
	position: absolute;
	top: 140px;
	right: 0;
}

aside:not(.grid-view).participants-multiple > section > .participant-wrapper.active-participant-wrapper {
	top: 160px;
}

aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper .participant > main,
aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper .participant > div:not(.confirmation-modal),
aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper .participant > header > p {
	display: none;
}

aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper .participant > header > div {
	margin-left: auto;
}

aside.participants-length-low .participants-number {
	display: none;
}

aside:not(.grid-view) .participant header .list-group li a i {
	display: none;
}

.dark-theme .list-group li a i {
	color: var(--gray-0);
}

.header .badge {
	border-radius: 20px;
	font-size: 14px;
	color: var(--gray-0);
	padding: var(--spacing-s) var(--spacing-m) !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header .badge > i {
	font-size: 18px;
	top: 0;
	margin-left: 7px;
}

.header .logo > img,
.call-view .logo > img {
	height: 50px;
}

.hs-modal-wrapper.modal .modal__wrapper,
.dark-theme .hs-modal-wrapper.modal .modal__wrapper {
	width: calc(25% - 22px);
	left: 90px;
	min-width: initial;
}

.hs-modal-wrapper.modal .modal__content {
	height: auto;
}

.hs-modal-wrapper.modal .modal__actions {
	display: flex;
	position: static;
	align-items: center;
	justify-content: center;
}

.hs-modal-wrapper.modal .modal__actions .button {
	border-radius: 25px;
}

.modal.invite-people input,
.modal.invite-people .react-tel-input .form-control {
	color: var(--gray-0);
	font-size: 14px;
}

.modal.invite-people input[type='search'] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.modal.invite-people .react-tel-input .form-control {
	padding: 23px var(--spacing-l);
}

.modal.invite-people aside {
	position: relative;
}

.modal.invite-people .participant-wrapper,
.modal.invite-people .participant {
	width: 100%;
}

.modal.invite-people .participant-wrapper {
	position: relative !important;
	top: 0 !important;
	display: block !important;
}

.modal.invite-people .participant {
	position: relative;
	top: 0;
}

.modal.invite-people .participant main {
	max-width: 100% !important;
	width: auto;
	height: auto;
	padding: 0 !important;
	overflow: initial !important;
	display: block !important;
}

.modal.invite-people .participant main > div {
	display: block !important;
}

.modal.invite-people .participant main > video,
.modal.invite-people .participant-volume {
	display: none !important;
}

.modal.invite-people .participant header p {
	background: transparent;
	display: block !important;
}

.modal.invite-people .profile-picture-wrapper {
	width: 24px;
	height: 24px;
}

.modal.invite-people .chat-list-item-initials {
	font-size: 12px;
}

.modal.invite-people .participant {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.modal.invite-people .participant header {
	position: relative;
	z-index: initial;
	width: 100%;
}

.modal.invite-people section {
	width: 100%;
	flex-direction: column;
}

.modal.invite-people .mic_off-icon {
	display: none;
}

.modal.invite-people .modal__content > div > h3:first-of-type {
	margin: 0;
	padding-bottom: 0;
}

.dark-theme .tilt__zoom button {
	background: none;
}

.dark-theme .tilt__zoom button > i {
	color: var(--gray-0);
}

.modal.invite-people:not(.cam-settings-modal) {
	width: 500px;
	left: 90px;
}

.modal.invite-people.cam-settings-modal {
	z-index: 1000;
}

.modal.invite-people.cam-settings-modal .modal__wrapper {
	width: 400px;
	min-width: 400px;
	margin-top: -50px;
}

.modal.invite-people.cam-settings-modal.tech-check-modal .modal__wrapper {
	width: 875px;
	max-width: 100%;
	min-width: 875px;
}

.modal.invite-people > .modal__wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.modal.invite-people:not(.cam-settings-modal) .modal__wrapper {
	min-width: 500px;
}

.modal.invite-people.cam-settings-modal .modal__wrapper {
	height: auto;
}

.modal.invite-people .modal__content {
	height: auto;
	max-height: 100vh;
}

.modal.invite-people .modal__actions {
	position: static;
	display: flex;
	justify-content: center !important;
}

.modal.invite-people .modal__actions .button {
	border-radius: 25px;
	font-size: 20px;
	font-weight: 700;
}

.modal.invite-people .modal__content > div {
	padding-top: var(--spacing-ssl);
}

.modal.invite-people .modal__content > div > * {
	flex: 1;
	padding: 0 var(--spacing-ssl) var(--spacing-l);
}

.modal.invite-people .modal__content > div > form {
	border-right: 1px solid var(--gray-1);
	overflow-x: auto;
}

.modal.invite-people h2 {
	font-size: 28px;
}

.modal.invite-people h3 {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
	margin-bottom: var(--spacing-l);
	margin-top: var(--spacing-xl);
	color: var(--gray-0);
}

.modal.invite-people .modal__content input {
	background: var(--gray-1);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.modal.invite-people .modal__content input.invite-add-email {
	background: var(--gray-1) url('https://static.solaborate.com/americanwell/amwell-icons/add-email.svg') no-repeat;
	background-size: 15px;
	background-position: left 7px center;
}

.modal.invite-people .modal__content .input {
	margin-bottom: var(--spacing-l);
}

.modal.invite-people .modal__content .input .label {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.modal.invite-people .modal__content .input .label > img {
	width: 20px;
	margin-right: 7px;
}

.modal.invite-people .participants-number {
	display: none;
}

.modal.cam-settings-modal .modal__wrapper {
	border-radius: 10px;
}

.modal.cam-settings-modal .modal__content {
	height: auto;
	max-height: initial;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.modal.cam-settings-modal .input:not(.select-wrapper) {
	margin-bottom: var(--spacing-m);
}

.modal.cam-settings-modal .modal__content .input.select-wrapper {
	margin-bottom: var(--spacing-xl);
}

.modal.cam-settings-modal .modal__actions {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.modal.cam-settings-modal:not(.tech-check-modal) .modal__content > div > div:first-of-type {
	padding: var(--spacing-xxl);
	background: var(--gray-1);
	border-bottom: 1px solid var(--dark-theme-black-005);
}

.modal.cam-settings-modal .modal__close {
	top: var(--spacing-xxl);
	color: var(--gray-3);
}

.modal.cam-settings-modal .modal__close i {
	font-size: 18px;
}

.modal.cam-settings-modal .modal__content > div > div:first-of-type h3 {
	padding: 0;
	margin: 0;
	position: relative;
	top: -1px;
	font-size: 22px;
}

.modal.cam-settings-modal .modal__content > div > div:last-of-type {
	padding: var(--spacing-xxl);
}

.modal.cam-settings-modal .modal__content ul.tabs {
	width: auto;
	padding-right: 0;
	height: 100%;
}

.modal.cam-settings-modal .modal__content ul.tabs + div {
	width: 100%;
	padding: var(--spacing-xxl);
}

.modal.cam-settings-modal .modal__content ul.tabs li {
	flex: none;
	cursor: pointer;
}

.modal.cam-settings-modal .modal__content ul.tabs li > a {
	padding: var(--spacing-l) var(--spacing-xxl);
}

.modal.cam-settings-modal .modal__content ul.tabs li:hover {
	border-bottom: none;
}

.modal.cam-settings-modal .modal__content ul.tabs li.active {
	border-bottom: 1px solid var(--gray-0);
	background: var(--dark-theme-gray-4);
}

.modal.invite-people .modal__content .input select {
	-webkit-appearance: none;
}

.modal.cam-settings-modal .modal__content .input select:focus {
	outline: none;
}

.modal.invite-people .modal__content .input small {
	font-size: 12px;
	color: var(--gray-3);
}

.modal.invite-people .modal__actions {
	justify-content: flex-start;
	background: var(--gray-0);
	padding-top: 0;
	padding-bottom: var(--spacing-ssl);
}

.modal.invite-people:not(.cam-settings-modal) .modal__actions {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-l);
}

.left-align-items {
	align-items: flex-start;
}

.right-align-items {
	align-items: flex-end;
}

.flex-col {
	flex-direction: column;
}

.flex-align-center {
	align-items: center;
}

.flex-align-end {
	align-items: flex-end;
}

.flex-justify-center {
	justify-content: center;
}

.flex-space-between {
	justify-content: space-between;
}

.flex-space-around {
	justify-content: space-around;
}

.full-width {
	width: 100% !important;
}

.modal.cam-settings-modal .tabs {
	border-bottom: none;
}

.modal.cam-settings-modal .modal__actions {
	display: none;
}

.modal.cam-settings-modal .input > .label:not(.custom-label) {
	position: absolute;
	top: 4px;
	left: 14px;
	font-size: 12px;
	color: var(--gray-2);
}

.modal.cam-settings-modal .input select {
	padding-top: var(--spacing-xxl);
}

.tv-controls-dropdown .list-group img {
	width: 24px;
	margin-right: 13px;
}

.tv-controls-dropdown .list-group li:hover {
	background: none;
}

.tv-controls-dropdown .list-group li span {
	width: auto;
	flex-shrink: 0;
}

.tv-controls-dropdown .list-group li button i {
	margin-right: 0;
}

.dark-theme .modal.cam-settings-modal .tabs {
	box-shadow: none;
}

.modal.invite-people.cam-settings-modal .modal__actions {
	justify-content: flex-end;
	padding: var(--spacing-xl);
	position: relative;
	top: 1px;
}

.modal.invite-people.cam-settings-modal .modal__actions .button {
	width: auto;
	padding: var(--spacing-m) var(--spacing-xxl);
	border-radius: 25px;
}

.modal.cam-settings-modal .modal__content > div {
	height: 100%;
	padding-top: 0;
}

.modal.invite-people .modal__actions .button {
	padding: var(--spacing-m) var(--spacing-sssl);
	width: calc(50% - var(--spacing-ssl));
	text-align: center;
	display: flex;
	justify-content: center;
	background: var(--blue-1);
	border: none;
}

.modal.invite-people .modal__actions .button:first-of-type {
	display: none;
}

.modal.confirmation-modal .modal__actions .button:first-of-type {
	display: block;
}

.call-invite-people-form .header-searchbar-results {
	bottom: -35px;
	top: auto;
	width: calc(100% - var(--spacing-ssl) - var(--spacing-ssl));
}

.invite-modal-participant {
	margin-bottom: var(--spacing-m);
}

.invite-modal-participant header {
	display: flex;
	align-items: center;
}

.invite-modal-participant header > p {
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invite-modal-participant header .dropdown {
	padding: 0;
}

.invite-modal-participant header img {
	margin-right: var(--spacing-m);
}

.invite-modal-participant header i {
	margin: 0;
}

.invite-modal-participant header > div:first-of-type {
	margin-right: var(--spacing-s);
	flex: 0 0 32px;
}

.invite-participants-list {
}

.invite-participants-list > div {
	background: var(--dark-theme-gray-5);
	padding: 10px;
}

.invite-participants-list p {
	font-size: 14px;
	margin: 0;
	padding: 0;
}

.invite-participants-list > div > div {
	align-items: center;
	justify-content: space-between;
	margin-top: var(--spacing-l);
}

.invite-participants-list > div > div:first-of-type {
	margin-top: 0;
}

.invite-participants-list > div > div p {
	flex-basis: calc(100% - 40px);
}

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

.position-relative {
	position: relative;
}

.column-direction {
	flex-direction: column;
}

.flex-align-center {
	align-items: center;
}

.flex-justify-center {
	justify-content: center;
}

.call-invite-people-form .badge.green {
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 16px;
}
.call-invite-people-form input {
	border: 1px solid var(--gray-3);
	border-radius: 5px;
	padding: 10px;
	width: 100%;
}
.invite-people .badge {
	margin-right: var(--spacing-s);
}
.invite-people main {
	position: relative;
}
.invite-people .invite-copy {
	position: relative;
	margin-top: var(--spacing-sl);
}
.invite-people .invite-copy button {
	border-radius: var(--border-radius);
	cursor: pointer;
	z-index: 2;
	border: none;
	width: 100%;
	background: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: var(--spacing-l);
	margin-top: -10px;
}

.invite-people .invite-copy button i {
	color: var(--gray-3);
	font-size: 14px;
}
.invite-people .invite-copy button span {
	font-size: 16px;
	margin-left: 5px;
	color: var(--dark-theme-blue-1);
}

.invite-people .invite-copy button img {
	height: 15px;
}

.modal.invite-people .modal__content form > button {
	width: 100%;
	padding: var(--spacing-m) var(--spacing-sssl);
	text-align: center;
	display: flex;
	justify-content: center;
	background: rgba(37, 171, 252, 0.1);
	border: none;
	color: var(--blue-1);
	border-radius: var(--border-radius);
	font-size: 14px;
}

.modal.invite-people .modal__content form > button i {
	font-size: 20px;
	margin-right: 5px;
}

.modal.invite-people .react-tel-input .form-control {
	width: 100%;
	padding-left: 40px;
}

.modal.invite-people .react-tel-input .selected-flag:hover,
.modal.invite-people .react-tel-input .selected-flag:focus {
	background: transparent;
}

.modal.invite-people .react-tel-input .flag-dropdown {
	border: none;
	background: transparent;
}

.modal.invite-people .react-tel-input .flag-dropdown.open .selected-flag {
	background: transparent;
}

.modal.invite-people .react-tel-input .country-list {
	max-height: 130px;
	width: 210px;
}

.modal.invite-people .react-tel-input .country-list .country span {
	font-size: 12px;
	color: var(--gray-4);
}

.modal.confirmation-modal .modal__content {
	padding: 0 var(--spacing-l);
}

.confirmation-modal.modal form {
	text-align: center;
}

.confirmation-modal.modal h3:empty {
	display: none;
}

.confirmation-modal.modal .modal__wrapper {
	border-radius: 10px;
}

.confirmation-modal.modal p {
	margin-top: 0;
	padding-top: 0;
	font-size: 20px;
}

.confirmation-modal.modal .modal__actions {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	justify-content: center;
	padding-bottom: var(--spacing-sl);
}

.confirmation-modal.modal form {
	padding: var(--spacing-sl) 0 0;
}

.confirmation-modal.red-modal.modal .button + .button {
	background: var(--red-1);
	border: 1px solid var(--red-1);
}

.modal.tech-check-modal .modal__wrapper {
	min-width: initial;
	width: 900px;
	max-width: 99%;
}

.tech-check-content {
	justify-content: space-between;
}

.tech-check-content > div {
	flex-basis: 49%;
}

.tech-check-content video {
	width: 100%;
	margin-bottom: var(--spacing-l);
}

.tech-check-content > div > .input > .label:not(.custom-label) {
	position: absolute;
	top: 4px;
	left: 14px;
	font-size: 12px;
	color: var(--gray-2);
}

.tech-check-content .input select {
	padding-top: var(--spacing-xxl);
}

.tech-check-content .media-controls.play-btn {
	margin: 0 0 var(--spacing-l);
	align-items: center;
}

.tech-check-content .input > .label {
	display: flex;
	align-items: center;
}

.tech-check-content .media-controls.play-btn > div {
	width: auto;
	border: none;
	margin: 0;
	background: none;
}

.tech-check-content .media-controls.play-btn > div > img {
	width: 30px;
	cursor: pointer;
}

.tech-check-content .input .label > img {
	width: 20px;
	margin-right: 7px;
}

.tech-check-content .media-controls.play-btn > div.play-btn-text {
	margin-left: 5px;
}

.tech-check-content .media-controls.play-btn > div.play-btn-text > p {
	color: var(--gray-0);
	padding: 0;
	margin: 0;
}

.audio-check-progress-bar {
	animation-name: audioProgressBarAnimation;
	height: 8px;
	background: var(--dark-theme-blue-1);
	animation-timing-function: linear;
}

@keyframes audioProgressBarAnimation {
	0% {
		width: 0;
	}
}

@media (max-width: 568px) {
	.amwell-qr .login__wrapper {
		width: auto !important;
		margin-bottom: var(--spacing-xl);
	}

	.amwell-qr .login-form-wrapper {
		padding: var(--spacing-xxl);
	}

	.amwell-qr > div:first-of-type img {
		width: 150px;
	}

	.amwell-qr h3 {
		font-size: 20px;
		line-height: 26px;
	}

	.sign-up-flow::before {
		background-image: linear-gradient(294deg, #3caea3, #20639b);
	}

	.sign-up-flow main h1 {
		font-size: 24px;
	}

	.sign-up-flow main p {
		font-size: 14px;
	}
}

/* Grid */
.call-view .grid-view {
	height: 100vh;
	width: calc(100% - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
	left: 90px;
	top: 0;
	background: var(--gray-6);
}

.grid-view section {
	display: grid;
	max-width: 100%;
}

.grid-view .participant-wrapper {
	position: relative;
	overflow: hidden;
	background: #ddd;
}

.grid-view .participant-wrapper video {
	height: 100%;
}

.grid-view .participant {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.grid-view .participant header {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 0;
	opacity: 0;
	transition: 0.15s;
	background: transparent;
	z-index: 1;
	align-items: center;
	justify-content: center;
}

.grid-view .participant header > p {
	display: none;
}

.grid-view .participant .dropdown__items {
	opacity: 1;
	visibility: visible;
	background: transparent;
	position: relative;
}

.grid-view .participant .dropdown > a {
	display: none;
}

.grid-view .participant .list-group {
	background: rgba(0, 0, 0, 0.5);
	box-shadow: none;
	display: flex;
	flex-direction: row;
	border-radius: 30px;
	padding: 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
}

.grid-view .participant .list-group li:last-of-type {
	border: none;
}

.grid-view .participant .list-group li a {
	margin: 0;
	padding: var(--spacing-m) var(--spacing-m);
}

.grid-view .participant .list-group li a > span {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 50px;
	color: var(--gray-0);
	background: var(--gray-5);
	padding: 4px 8px;
	border-radius: 4px;
	opacity: 0;
}

.grid-view .participant .list-group li:hover {
	background: transparent;
}

.grid-view .participant .list-group li a:hover > span {
	opacity: 1;
}

.grid-view .participant .list-group li i {
	margin: 0;
	color: var(--gray-0);
	font-size: 24px;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.grid-view .participant header p,
.grid-view .participant .dropdown > a > i {
	color: var(--gray-0);
}

.grid-view .participant img {
	width: 140px;
}

.grid-view .participant:hover header {
	opacity: 1;
	display: flex;
}

.grid-view .call-stats,
.grid-view .toggle-right-column {
	display: none;
}

.grid-view .participant main {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #414b58;
}

.grid-view .participant main::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
	opacity: 1;
}

.call-view aside:not(.call-left-menu):not(.grid-view) .participant main:not(.participant-has-video) {
	padding-bottom: 25px;
}

.participant main footer {
	position: absolute;
	font-size: 0;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	right: 6px;
	top: 6px;
}

.grid-view .participant main footer {
	right: auto;
	left: 0;
	bottom: 0;
	font-size: 14px;
	padding: var(--spacing-m);
	align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
	top: auto;
}

.grid-view .participant main footer p {
	color: var(--gray-0);
	padding: 0;
	margin: 0;
	line-height: 15px;
	white-space: nowrap;
}

.grid-view .participant main footer i {
	margin-right: var(--spacing-m);
}

.grid-2,
.grid-3,
.grid-4 {
	grid-template-columns: repeat(2, 1fr);
}

.grid-2 .participant-wrapper {
	width: calc(16 / 9 * 100vh);
	max-width: calc(50vw - 45px);
	height: calc(9 / 16 * (50vw - 45px));
	max-height: 100vh;
}

.grid-3 .participant-wrapper:not(.pinned-participant):not(.screen-local-participant-wrapper):last-of-type {
	left: 50%;
}

.grid-3 .participant-wrapper.pinned-participant ~ .participant-wrapper {
	left: auto;
}

.grid-3 .participant-wrapper,
.grid-4 .participant-wrapper {
	width: calc(16 / 9 * 50vh);
	max-width: calc(50vw - 45px);
	height: calc(9 / 16 * (50vw - 45px));
	max-height: 50vh;
}

.grid-5,
.grid-6 {
	grid-template-columns: repeat(3, 1fr);
}

.grid-5 .participant-wrapper,
.grid-6 .participant-wrapper {
	width: calc(16 / 9 * 50vh);
	max-width: calc(33.333vw - 30px);
	height: calc(9 / 16 * (33.333vw - 30px));
	max-height: 50vh;
}

.grid-7,
.grid-8,
.grid-9 {
	grid-template-columns: repeat(3, 1fr);
}

.grid-7 .participant-wrapper,
.grid-8 .participant-wrapper,
.grid-9 .participant-wrapper {
	width: calc(16 / 9 * 33.333vh);
	max-width: calc(33.333vw - 30px);
	height: calc(9 / 16 * (33.333vw - 30px));
	max-height: 33.333vh;
}

.grid-10,
.grid-11,
.grid-12,
.grid-13,
.grid-14,
.grid-15,
.grid-16 {
	grid-template-columns: repeat(4, 1fr);
}

.grid-10 .participant-wrapper,
.grid-11 .participant-wrapper,
.grid-12 .participant-wrapper {
	width: calc(16 / 9 * 33.333vh);
	max-width: calc(25vw - 22.5px);
	height: calc(9 / 16 * (25vw - 22.5px));
	max-height: 33.333vh;
}

.grid-13 .participant-wrapper,
.grid-14 .participant-wrapper,
.grid-15 .participant-wrapper,
.grid-16 .participant-wrapper {
	width: calc(16 / 9 * 25vh);
	max-width: calc(25vw - 22.5px);
	height: calc(9 / 16 * (25vw - 22.5px));
	max-height: 25vh;
}

/* End Grid */

.call-view .logo {
	top: var(--spacing-xl);
	left: calc(var(--spacing-xl) + 90px);
	position: absolute;
}

.call-view.patient-view .logo {
	left: var(--spacing-xl);
}

.call-view .main-not-expanded .logo {
	left: calc(var(--spacing-xl) + 590px);
}

.guest-join {
	text-align: center;
	background: var(--gray-6);
}

.guest-join p {
	font-size: 20px;
	color: var(--gray-0);
}

.guest-join video {
	background: var(--gray-5);
	width: 100%;
	min-height: 280px;
}

.guest-join main {
	position: relative;
	display: inline-block;
	width: 100%;
	font-size: 0;
}

.guest-join main::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 30%);
}

.guest-join main footer {
	position: absolute;
	bottom: var(--spacing-xl);
	right: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 1;
}

.guest-join button {
	border: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.guest-join footer > button:first-of-type {
	margin-right: var(--spacing-l);
}

.guest-join footer.cam-mic-btn {
	display: none;
}

.guest-join > div {
	width: 900px;
	display: flex;
	align-items: center;
}

.guest-join main {
	flex: 3;
	margin-right: var(--spacing-ssl);
}

.guest-join > div > div {
	flex: 2;
}

.guest-join form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.guest-join form .button {
	height: 100%;
	display: flex;
	margin: var(--spacing-xxl) auto 0 auto;
}

.guest-join form .input {
	width: 100%;
	margin-right: var(--spacing-m);
}

.guest-join .incoming-calls {
	background: var(--indigo-1);
}

.guest-join .incoming-calls p {
	font-size: 16px;
}

.guest-join footer > button {
	padding: auto;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.guest-join footer > button i {
	width: 30px;
}

.incoming-calls.end-call {
	background: var(--gray-6);
}

.invited-member {
	display: flex;
	padding: 0 var(--spacing-s);
	margin-bottom: var(--spacing-l);
	position: relative;
	z-index: 1;
	border-bottom: 1px solid var(--green-1);
	width: 100%;
}

.invited-member button {
	cursor: pointer;
	border: none;
	background: transparent;
	padding: 0;
}

.invited-member .badge {
	background: transparent;
	color: var(--gray-4);
	margin-right: 0;
	width: calc(100% - 15px);
	padding: 7px !important;
	padding-right: 0 !important;
}

.invited-member button i {
	font-size: 12px;
}

.invited-member + .input,
.invited-member + main .input {
	position: relative;
}

.invited-member + .input .label,
.invited-member + main .input .label {
	position: relative;
	top: -20px;
}

.notification:empty {
	display: none;
}

.notification {
	position: fixed;
	top: -100px;
	left: var(--spacing-l);
	background: var(--gray-5);
	padding: var(--spacing-m);
	color: var(--gray-0);
	z-index: 500;
	font-size: 14px;
	border-radius: var(--border-radius);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	animation: show-up 5s;
}

.video-feed-skeleton {
	background: var(--gray-5);
	height: calc(100vh - 150px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.dark-theme .video-feed-skeleton {
	background: var(--dark-theme-gray-4);
}

.video-feed-skeleton p {
	color: var(--gray-0);
	font-size: 14px;
	font-weight: 500;
}

.video-feed-skeleton header {
	width: 100%;
	background: var(--dark-theme-gray-2);
	padding: 12px;
}

.video-feed-skeleton div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

.dark-theme {
	--blue-1: var(--dark-theme-blue-1);
	--indigo-1: var(--dark-theme-blue-1);
	--yellow-1: var(--dark-theme-yellow-1);
}

.dark-theme select option {
	background: var(--dark-theme-gray-4);
	color: var(--dark-theme-gray-0);
}

.dark-theme .modal .modal__close i {
	color: var(--dark-theme-gray-0);
}

.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme p,
.dark-theme span {
	color: var(--gray-0);
}

.dark-theme input,
.dark-theme select,
.dark-theme .modal.invite-people input {
	background: var(--dark-theme-black-005);
	border-bottom: 1px solid var(--dark-theme-gray-0);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: var(--dark-theme-gray-1);
}

.dark-theme .input.filled input {
	background: rgba(255, 255, 255, 0.1);
}

.dark-theme input::placeholder,
.dark-theme select::placeholder,
.dark-theme .modal.invite-people input::placeholder {
	color: var(--dark-theme-gray-1);
}

.dark-theme .left-navigation {
	background: var(--dark-theme-gray-5);
}

.dark-theme .hello-list,
.dark-theme .dropdown.bottom.dropdown--open .dropdown__items,
.dark-theme .list-group {
	background: var(--dark-theme-gray-4-v2);
}

.dark-theme .tree .selected::before {
	background: var(--dark-theme-gray-3);
}

.dark-theme .tree i,
.dark-theme .tree .link {
	color: var(--dark-theme-gray-1);
	background: none;
	border: none;
	text-align: left;
}

.dark-theme .dropdown > a,
.dark-theme .dropdown > a i {
	color: var(--dark-theme-gray-0);
}

.dark-theme .main-view {
	background: var(--dark-theme-gray-3);
}

.dark-theme .collapse-second-column {
	background: var(--dark-theme-gray-2);
}

.dark-theme .modal .modal__wrapper {
	background: var(--dark-theme-gray-4);
}

.dark-theme .modal .modal__content {
	background: transparent;
}

.dark-theme .modal .modal__actions {
	background: var(--dark-theme-gray-4);
}

.dark-theme .tabs {
	background: var(--dark-theme-black-005);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.dark-theme .tabs li a {
	color: var(--dark-theme-gray-1);
}

.dark-theme .custom-select__control {
	background: rgba(255, 255, 255, 0.1);
	border: none;
	color: var(--dark-theme-gray-1);
}

.dark-theme .custom-select__single-value,
.dark-theme .custom-select__placeholder {
	color: var(--dark-theme-gray-1);
}

.dark-theme .alert.neutral {
	background: var(--dark-theme-gray-4);
	color: var(--gray-1);
}

.dark-theme .badge.green {
	background: var(--dark-theme-green-1);
}

.dark-theme .badge.red {
	background: var(--dark-theme-salmon-1);
	color: var(--gray-0);
}

.dark-theme .badge.green i,
.dark-theme .badge.red i {
	color: var(--gray-0);
}

.dark-theme .hello-feature-block,
.dark-theme .hello-properties li {
	background: none;
}

.dark-theme .button.white {
	background: var(--dark-theme-gray-2);
}

.dark-theme .modal .button.white {
	background: var(--dark-theme-gray-4);
}

.dark-theme .button.red {
	background: var(--dark-theme-salmon-1);
}

.dark-theme .table {
	background: var(--dark-theme-gray-2);
}

.dark-theme .table main > div,
.dark-theme .table header {
	border-bottom: none;
}

.dark-theme .table main > div:nth-child(odd) {
	background: var(--dark-theme-gray-3);
}

.dark-theme .table main > div > div {
	color: var(--dark-theme-gray-1);
}

.dark-theme .skeleton-loader > div {
	background: linear-gradient(90deg, var(--dark-theme-gray-4), var(--dark-theme-gray-4));
}

.dark-theme .skeleton-loader > div:after {
	background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%);
}

.dark-theme .monitoring-feeds > div {
	background: var(--dark-theme-gray-4);
}

.monitoring-feeds.zoomed-feed > div:not(.expand-feed):not(:nth-last-of-type(-n + 2)) .dropdown.precautions-dropdown .dropdown__items {
	right: auto;
	left: 0;
	background: transparent;
}

.dark-theme .monitoring-feeds > div > header {
	background: var(--dark-theme-gray-2);
}

.dark-theme .loader__container {
	background: var(--dark-theme-gray-5);
}

.dark-theme .call-view {
	background: var(--dark-theme-gray-3);
}

.dark-theme .center-loader p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .call-stats > div {
	background: var(--dark-theme-gray-3);
}

.dark-theme .call-stats > div p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .participant main {
	background: var(--dark-theme-gray-4);
}

.dark-theme .participant main > video + div {
	display: none;
}

.dark-theme .toggle-right-column {
	background: var(--dark-theme-gray-2);
}

.dark-theme .call-button {
	background: var(--dark-theme-gray-2);
}

.dark-theme .call-button.active {
	background: var(--blue-2);
}

.dark-theme .dropdown__items .call-button {
	background: transparent;
}

.dark-theme .dropdown__items .call-button-wrapper {
	background: var(--dark-theme-gray-4);
}

.dark-theme .dropdown-inner-header {
	background: var(--dark-theme-gray-3);
}

.dark-theme .grid-view .participant header {
	background: transparent;
}

.dark-theme .dropdown.call-dropdown-btn .dropdown__items:after {
	border-top: solid 10px var(--dark-theme-gray-3);
	border-left: solid 10px transparent;
	border-right: solid 10px transparent;
}

.dark-theme .participant .mic_off-icon {
	background: var(--dark-theme-salmon-1);
}

.dark-theme .participant .participant-volume span {
	background: var(--dark-theme-blue-1);
}

.dark-theme .grid-view .participant .list-group li a:hover > span {
	background: var(--dark-theme-gray-4);
}

.dark-theme .call-button.active.end {
	background: var(--red-1);
}

.dark-theme [data-tooltip]::before,
.dark-theme [data-tooltip]::after {
	background: var(--dark-theme-gray-4);
}

.dark-theme .user-error {
	background: var(--dark-theme-gray-5);
}

.dark-theme .create-hs {
	background: var(--dark-theme-gray-5);
}

.dark-theme .create-hs form {
	background: var(--dark-theme-gray-3);
}

.dark-theme .sign-up-flow {
	background: var(--dark-theme-gray-4);
	height: 100vh;
	overflow: auto;
}

.dark-theme .sign-up-flow main {
	background: var(--dark-theme-gray-3);
}

.dark-theme .sign-up-flow main h1,
.dark-theme .sign-up-flow main p,
.dark-theme .sign-up-flow.step-1 .container + span {
	color: var(--dark-theme-gray-0);
}

.dark-theme .sign-up-flow .button {
	background: var(--dark-theme-blue-1);
}

.dark-theme .sign-up-flow footer {
	background: var(--dark-theme-gray-2);
	color: var(--dark-theme-gray-1);
}

.dark-theme .sign-up-flow::before {
	background: var(--dark-theme-gray-5);
}

.dark-theme .conference-loading {
	background: var(--dark-theme-gray-5);
}

.dark-theme .conference-loading p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .conference-not-active,
.dark-theme .guest-join {
	background: var(--dark-theme-gray-5);
}

.dark-theme .guest-join video {
	background: var(--dark-theme-gray-2);
}

.dark-theme .conference-not-active h4,
.dark-theme .guest-join p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .button.blue {
	background: var(--blue-2);
}

.dark-theme .log-out-user {
	background: var(--dark-theme-gray-5);
}

.dark-theme .log-out-user h3,
.dark-theme .log-out-user p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .login {
	background: var(--dark-theme-gray-4);
}

.dark-theme .login .login-form-wrapper {
	background: var(--dark-theme-gray-3);
}

.dark-theme .warning-message {
	background: var(--dark-theme-gray-2);
	border-radius: var(--border-radius);
	border: none;
}

.dark-theme .action i,
.dark-theme .InputFromTo i {
	color: var(--dark-theme-gray-0);
}

.dark-theme .DayPickerInput > input {
	width: 100%;
	background: transparent;
	border: none;
}

.dark-theme .DayPickerInput-Overlay {
	background: var(--dark-theme-gray-4);
}

.dark-theme .DayPickerInput-Overlay .DayPicker-Day,
.dark-theme .DayPicker-Caption {
	color: var(--dark-theme-gray-1);
}

.dark-theme .DayPicker-Day.DayPicker-Day--disabled {
	opacity: 0.5;
	/* pointer-events: none; */
}

.dark-theme .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background: var(--dark-theme-gray-3) !important;
}

.dark-theme .DayPicker-Day--today {
	color: var(--dark-theme-salmon-1);
}

.dark-theme .modal.invite-people .modal__content input {
	background: var(--dark-theme-black-005);
	border-bottom: 1px solid var(--dark-theme-gray-0);
}

.dark-theme .modal.invite-people .modal__content > div > form {
	border-right: 1px solid var(--dark-theme-gray-2);
}

.dark-theme .tilt__zoom--text {
	color: var(--dark-theme-gray-0);
}

.dark-theme .call-button,
.dark-theme .call-dropdown-btn > a {
	background: var(--dark-theme-gray-4);
}

.dark-theme .call-view .volume-dropdown span {
	color: var(--gray-0);
}

.dark-theme .call-view .volume-dropdown.mpi-volume-dropdown span {
	width: 15px;
}

.dark-theme .input .label + p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .create-hs-success {
	background: var(--dark-theme-gray-5);
}

.dark-theme .create-hs-success h3 {
	color: var(--dark-theme-gray-0);
}

.dark-theme .create-hs-success i {
	background: var(--dark-theme-green-1);
}

.dark-theme .custom-select__menu-list > div {
	background: var(--dark-theme-gray-4);
	color: var(--dark-theme-gray-0);
}

.dark-theme .custom-select__menu-list > div:hover {
	background: var(--dark-theme-gray-2);
}

.dark-theme .custom-select__menu-list {
	background: var(--dark-theme-gray-4);
}

.dark-theme .call-view__footer--desc h3,
.dark-theme .call-view__footer--desc p {
	color: var(--dark-theme-gray-0);
}

.dark-theme .invite-people form > div:first-of-type {
	position: relative;
}

.dark-theme .modal.invite-people input {
	background: var(--dark-theme-gray-4) url('https://static.solaborate.com/americanwell/amwell-icons/add-email.svg') no-repeat;
	background-size: 15px;
	background-position: left 7px center;
}

.dark-theme .cam-settings-header {
	background: var(--dark-theme-gray-4) !important;
}

.media-settings-header > p {
	padding: 0;
	margin: 0;
	margin-bottom: var(--spacing-l);
}

.dark-theme .invited-member {
	border-bottom: 1px solid var(--dark-theme-gray-2);
}

.dark-theme .invited-member .badge,
.dark-theme .invited-member i {
	background: transparent;
	color: var(--dark-theme-gray-1);
}

.dark-theme .modal.loading .modal__content::before {
	background: var(--dark-theme-gray-3);
}

.dark-theme .description li {
	color: var(--dark-theme-gray-0);
}

.dark-theme .description li span {
	color: var(--dark-theme-gray-1);
}

.monitoring-list .tree .tree__child i:not(.hide) {
	opacity: 1;
}

.call-view .call-view__footer.footer-maximized-frame {
	grid-template-columns: 1fr !important;
}

.call-view .call-view__footer.footer-maximized-frame > div:nth-of-type(2) {
	text-align: center;
}

.call-view .call-view__footer.footer-maximized-frame > div:nth-of-type(2) > div:nth-of-type(4) {
	margin-right: 0;
}

.monitoring-view .patient-view-control {
	top: 90px;
	right: auto;
}

.monitoring-view .patient-view-control.no-huddle-wrapper {
	top: 50px;
}

.only-bridge-connected > a:last-of-type {
	display: none;
}

.bookmark-list {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding-left: 0;
}

.call-view aside:not(.call-left-menu):not(.grid-view) > section.bookmark-list {
	padding-left: 0;
}

.bookmark-list > .bookmark-item,
.bookmark-list > .bookmark {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.bookmark-list > .bookmark-item:not(:first-of-type) {
	margin-top: var(--spacing-m);
}

.bookmark-list > .bookmark-item:last-of-type span {
	color: var(--blue-1);
}

.bookmark-list > .bookmark-item > span {
	font-size: 14px;
	cursor: pointer;
}

.bookmark-list > .bookmark-item > .bookmark-buttons {
	display: flex;
	justify-content: center;
}

.bookmark-list > .bookmark-item > .bookmark-buttons > span,
.bookmark-list > .bookmark > .bookmark-buttons span {
	cursor: pointer;
}

.bookmark-list-options {
	position: absolute;
	left: 150px;
	background: rgba(52, 58, 64, 0.8);
	color: #fff;
	top: 0;
	padding: 5px 20px;
	border-radius: 10px;
	width: 165px;
}

.bookmark-list-options > div {
	margin-bottom: 5px;
	cursor: pointer;
}

.bookmarks-wrapper {
	position: relative;
}

.bookmarks-wrapper > .bookmark-header {
	display: flex;
	justify-content: flex-start;
	gap: 5px;
	align-items: center;
	margin-top: 5px;
	cursor: pointer;
}
.bookmarks-wrapper > .bookmark-header:hover {
	color: var(--gray-0);
}
.bookmarks-wrapper > .bookmark-header > span {
	font-size: 14px;
	color: var(--gray-2);
}

.bookmark-form-wrapper form input {
	height: 60px;
	padding: 20px 10px;
	border: none;
	margin: 5px 0;
}
.bookmark-form-wrapper form span {
	font-size: 14px;
}

.bookmark-form-wrapper form span + span {
	font-size: 16px;
	margin-left: var(--spacing-s);
	position: relative;
	top: 2px;
	user-select: none;
}

.bookmark-form-wrapper form p {
	font-size: 12px;
}
.bookmark-form-wrapper .submit-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
}
.bookmark-form-wrapper .submit-buttons p {
	font-size: 16px;
	margin-bottom: 0;
	padding-top: 0;
}
.audio-is-stopped {
	cursor: pointer;
}

.audio-control-playing {
	cursor: auto;
	pointer-events: none;
	opacity: 0.5;
}

@keyframes show-up {
	0% {
		top: -50px;
	}
	10% {
		top: 15px;
	}
	80% {
		top: 15px;
	}
	100% {
		top: -50px;
	}
}

.call-view__footer > div:nth-child(2),
.call-view__footer > div:nth-child(3) {
	opacity: 0;
	visibility: visible;
	transition: 0.15s;
	z-index: 500;
}

.call-view:hover .call-view__footer > div:nth-child(2),
.call-view:hover .call-view__footer > div:nth-child(3),
.call-view:hover .call-view__footer .call-view__footer--desc,
.call-view:hover aside:not(.grid-view) {
	opacity: 1;
	visibility: visible;
}

.more-dropdown .dropdown-inner-header {
	display: none;
}

p.different-audio-selected {
	font-size: 13px;
	align-items: center;
}

.different-audio-selected > i {
	font-size: 16px;
	margin-right: 5px;
	color: var(--yellow-1);
}

.sound-permission-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--dark-theme-gray-4);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.modal.device-unavailable-modal {
	z-index: 9999;
	/* z-index equal to ptz in this case */
}

.modal.ai-event-modal {
	z-index: 10001;
	/* z-index higher than ptz in this case */
}

.modal.ai-event-modal textarea {
	width: 100%;
	resize: none;
	background: var(--dark-theme-black-005);
	border: 1px solid var(--dark-theme-black-005);
	border-radius: 4px;
	color: var(--dark-theme-gray-1);
}

.completing-registration {
	background: var(--dark-theme-gray-4);
}

.completing-registration p {
	color: var(--dark-theme-gray-0);
}

.connection-lost {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500;
	text-align: center;
}

.tv-controls-dropdown .list-group + button {
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	align-items: center;
	padding: var(--spacing-m) var(--spacing-sssl) var(--spacing-xl) var(--spacing-xl);
	background: var(--dark-theme-gray-4-v2);
	border: none;
	color: var(--gray-0);
	cursor: pointer;
	-webkit-appearance: none;
	top: -10px;
	border-radius: 6px;
}

.tv-controls-dropdown .list-group + button::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 10px;
	background: var(--dark-theme-gray-4-v2);
	top: -7px;
	left: 0;
}

.tv-controls-dropdown .list-group + button i {
	margin-right: 12px;
}

#patient-position-detection {
	background: var(--gray-0) url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/patient-position-detection.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#patient-position-detection.active {
	background: #dfe9f9 url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/patient-position-detection-on.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#bed-and-rails-detection {
	background: var(--gray-0) url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/bed-and-rails-detection.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#bed-and-rails-detection.active {
	background: #dfe9f9 url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/bed-and-rails-detection-on.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#fall-detection {
	background: var(--gray-0) url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/fall-detection.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#fall-detection.active {
	background: #dfe9f9 url(https://static.solaborate.com/healthcare-system/monitoring/video-feed/fall-detection-on.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#skeleton-detection {
	background: var(--gray-0) url(https://static.solaborate.com/healthcare-system/footer-icons/PrivacyOnSkeletonDetection-1.svg) no-repeat;
	background-position: center;
	background-size: 15px;
}

#skeleton-detection.active {
	background: var(--gray-0) url(https://static.solaborate.com/healthcare-system/footer-icons/PrivacyOnSkeletonDetection.svg?v2) no-repeat;
	background-position: center;
	background-size: 15px;
}

.monitoring-feeds:not(.feeds-1) > div:not(.expand-feed) #patient-position-detection,
.monitoring-feeds:not(.feeds-1) > div:not(.expand-feed) #bed-and-rails-detection,
.monitoring-feeds:not(.feeds-1) > div:not(.expand-feed) #fall-detection,
.monitoring-feeds:not(.feeds-1) > div:not(.expand-feed) #skeleton-detection {
	padding: 10px;
	background-size: 12px;
}

.monitoring-feeds:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) #patient-position-detection,
.monitoring-feeds:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) #bed-and-rails-detection,
.monitoring-feeds:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) #fall-detection,
.monitoring-feeds:not(.feeds-1):not(.feeds-2):not(.feeds-3):not(.feeds-4):not(.feeds-5):not(.feeds-6) > div:not(.expand-feed) #skeleton-detection {
	display: none;
}

/* Monitoring Alert */

.monitoring-feeds {
	position: relative;
}

.detection-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: none !important;
	max-width: calc(100% - 10px);
	width: calc(100% - 10px);
	z-index: 99;
	align-items: center;
	justify-content: center;
}

.wrapper-top {
	top: 15% !important;
}

.detection-wrapper.show {
	display: flex !important;
}

.detection-wrapper .alert-body.danger {
	border-radius: var(--spacing-sl);
	background: rgba(52, 58, 64, 0.8);
	border: none;
}

.detection-wrapper .alert-body.danger i,
.detection-wrapper .alert-body.danger p {
	color: var(--gray-0);
}

.detection-wrapper .alert-content > div:first-of-type {
	display: none;
}

.popup-alert-wrapper {
	position: fixed;
	top: var(--spacing-l);
	left: var(--spacing-l);
	align-items: flex-start;
	justify-content: flex-start;
	width: 450px;
	max-width: 100%;
	display: none;
	z-index: 999;
	visibility: hidden;
}

.popup-alert-wrapper.center-alert {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	justify-content: center;
}

.popup-alert-wrapper.center-top-alert {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	justify-content: center;
}

.popup-alert-wrapper.show {
	display: flex;
	visibility: visible;
}

.popup-alert-wrapper.right-alert {
	left: 17rem;
}

.popup-alert-wrapper.is-bottom {
	flex-direction: column;
	justify-content: flex-end;
	height: 110px;
	top: auto;
	bottom: var(--spacing-l);
	max-width: calc(100% - var(--spacing-sl));
	z-index: 1000;
}

.popup-alert-wrapper.right-bottom-alert {
	left: auto;
	right: 15px;
	bottom: 15px;
	transform: none;
	top: auto;
	justify-content: flex-end;
}

.alert-body {
	background: var(--gray-0);
	border-radius: var(--spacing-s);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	word-break: break-word;
}

.close-alert {
	font-size: 16px;
	cursor: pointer;
}

.detection-wrapper .alert-content p {
	font-size: 14px;
}

.fall-alert-body {
	max-width: initial;
}

.alert-content {
	display: flex;
	align-items: center;
}

.alert-content > div {
	padding: var(--spacing-l);
	display: flex;
	align-items: center;
}

.detection-wrapper .alert-content > div {
	padding: var(--spacing-m) var(--spacing-l) !important;
}

.alert-content > div:nth-of-type(2) {
	padding: var(--spacing-l) 0;
	flex: 1;
}

.alert-body.danger {
	background: #ffe5e5;
	border: 1px solid rgba(241, 94, 94, 0.2);
}

.alert-body.danger i,
.alert-body.danger p {
	color: #cd3737;
	font-weight: 500;
}

.alert-body.danger p {
	text-align: left;
}

.alert-body.success {
	background: #dcf8e9;
	border: 1px solid #059d4c;
}

.alert-body.success i,
.alert-body.success p {
	color: #059d4c;
	font-weight: 500;
}

.alert-body.warning {
	background: #fff7e0;
	border: 1px solid #cda11e;
}

.alert-body.warning i,
.alert-body.warning p {
	color: #cda11e;
	font-weight: 500;
}

.alert-body.information {
	border: none;
	background-color: var(--gray-5);
	color: var(--gray-0);
}

.alert-body.information i,
.alert-body.information p {
	color: var(--gray-0);
	font-weight: 500;
}

.alert-content p {
	margin: 0;
	padding: 0;
	font-size: 16px;
}

.alert-feed-sub-option {
	min-height: 80px;
}

.alert-feed-sub-options-wrapper {
	display: flex;
	text-align-last: center;
	cursor: pointer;
	margin-left: var(--spacing-s);
	margin-right: var(--spacing-s);
	margin-bottom: var(--spacing-m);
	justify-content: center;
}

.alert-body.danger p.forward-ms {
	text-align: center;
}

.alert-feed-sub-options-wrapper > div {
	border: 1px solid var(--gray-8-o10);
	border-radius: var(--spacing-m);
	width: 110px;
	background: var(--gray-0);
	margin: 0 5px;
}

.alert-feed-sub-options-wrapper img {
	margin-top: var(--spacing-m);
}

.alert-feed-sub-options-wrapper div img {
	width: var(--spacing-xl);
}

.alert-feed-sub-options-wrapper div p {
	font-size: 12px;
	margin-bottom: 0.5em;
	padding-top: 0;
}

.margin-top-xl {
	margin-top: var(--spacing-xl);
}

.fall-detected-red-warning.fall-is-detected {
	background-color: rgba(255, 0, 0, 0.6);
	animation: fallDetectionBlink 1s linear infinite;
	-webkit-animation-direction: alternate-reverse;
}

.fall-detected-red-warning.fall-is-detected {
	top: 30px;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
}

@keyframes fallDetectionBlink {
	from {
		opacity: 0.33;
	}

	to {
		opacity: 0.75;
	}
}

.pill-button {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--dark-theme-black-005);
	border-radius: 30px;
	position: relative;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	padding: var(--spacing-s) var(--spacing-m);
	font-size: 14px;
}

.pill-button input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
}

.pill-button h5 {
	color: var(--gray-2);
	white-space: nowrap;
}

.selected-pill.pill-button {
	background: var(--blue-2);
	color: var(--gray-0);
}

.selected-pill.pill-button h1,
.selected-pill.pill-button h5 {
	color: var(--gray-0);
}

.call-view.hide-controls .call-view__footer > div:nth-child(2) > div:not(.more-dropdown),
.call-view.hide-controls .call-view__footer > div:nth-child(3),
.call-view.hide-controls .call-view__footer .call-view__footer--desc,
.call-view.hide-controls aside:not(.grid-view):not(.call-left-menu):not(.invite-modal-participants),
.call-view.hide-controls footer .more-dropdown {
	opacity: 0;
	visibility: hidden;
}

@media only screen and (min-width: 991px) {
	.call-dropdown-btn.more-dropdown.dropdown .dropdown__items .call-button-wrapper.tv-controls-dropdown,
	.tv-controls-dropdown .dropdown-inner-header {
		display: none;
	}

	.more-dropdown > a,
	.tv-controls-dropdown > a {
		position: relative;
		bottom: 35px;
	}

	.more-dropdown.dropdown.top .dropdown__items,
	.tv-controls-dropdown.dropdown.top .dropdown__items {
		bottom: calc(100% + 50px);
		background: none;
	}

	.more-dropdown > a::after {
		content: 'More';
		position: absolute;
		font-size: 12px;
		left: 50%;
		top: calc(100% + 17px);
		transform: translate(-50%, -50%);
	}

	.tv-controls-dropdown > a::after {
		content: 'TV Controls';
		position: absolute;
		font-size: 12px;
		left: 50%;
		top: calc(100% + 17px);
		transform: translate(-50%, -50%);
		white-space: nowrap;
	}

	.patient-view-control > i.close-ptz {
		display: none;
	}

	.tilt__zoom {
		top: calc(var(--spacing-l) + var(--spacing-s));
	}

	.tilt__zoom input,
	.dark-theme .tilt__zoom input {
		width: 115px;
	}

	.call-dropdown-btn .dropdown__items > div:not(.dropdown-inner-header).ptz-btn-wrapper {
		display: none;
	}
}

@media only screen and (max-width: 990px) {
	main > .call-duration {
		display: none;
	}
	.call-view .left-navigation {
		text-align: left;
		flex-direction: row;
		padding: var(--spacing-l);
		position: relative;
		z-index: 1;
	}

	.call-dropdown-btn .dropdown__items .call-button-wrapper.tv-controls-dropdown {
		display: none;
	}

	.call-view .call-view-video {
		align-items: center;
	}

	.call-view > main video {
		width: 100%;
		height: auto;
		object-fit: initial;
	}

	.call-view aside:not(.call-left-menu):not(.invite-modal-participants) {
		position: fixed;
	}

	aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper {
		position: fixed;
		right: var(--spacing-l);
		top: 160px;
	}

	.privacy-buttons-enabled aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper {
		top: 190px;
	}

	aside.invite-modal-participants > section > .participant-wrapper.active-participant-wrapper {
		right: 0;
	}

	.call-view-video.call-view-video-multiple-participants {
		height: calc(100vh - 260px);
		margin-top: 140px;
	}

	.call-view-video.call-view-video-multiple-participants video,
	.privacy-buttons-enabled .call-view-video.call-view-video-multiple-participants video {
		height: auto;
	}

	.modal.invite-people:not(.cam-settings-modal) .modal__wrapper,
	.modal.invite-people.cam-settings-modal .modal__wrapper,
	.modal.invite-people.cam-settings-modal.tech-check-modal .modal__wrapper {
		max-width: 100%;
		min-width: 100%;
		width: 100%;
	}

	.modal.invite-people.cam-settings-modal.tech-check-modal .modal__wrapper {
		height: calc(100vh - 5px);
	}

	.modal.invite-people .modal__actions .button {
		width: auto;
	}

	.modal.center {
		z-index: 500;
	}

	.call-view .logo {
		left: var(--spacing-xl);
	}

	.privacy-buttons-enabled.call-view .logo {
		top: calc(var(--spacing-sssl) - 5px);
	}

	.guest-join > div {
		flex-wrap: wrap;
		width: 100% !important;
		height: 100vh;
		overflow: auto;
	}

	.dark-theme .guest-join .input.filled input,
	.dark-theme .guest-join .input.filled input::placeholder {
		color: var(--gray-0);
	}

	.call-stats {
		display: none;
	}

	.invite-participants-list {
		max-height: calc(100vh - 700px);
	}

	.guest-join form .button {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.guest-join video {
		width: 100%;
		height: 100vh;
		min-width: initial !important;
		object-fit: cover;
	}

	.guest-join main footer {
		display: none;
	}

	.guest-join footer.cam-mic-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: var(--spacing-xl);
	}

	.guest-join > div > div {
		position: fixed;
		bottom: 150px;
		left: 50%;
		transform: translate(-50%);
		width: 300px;
		max-width: 90%;
	}

	.guest-join .guest-joining-mobile {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
	}

	.guest-join main {
		margin-right: 0 !important;
	}

	.guest-join p {
		font-size: 16px !important;
	}

	.toggle-right-column {
		display: none;
	}

	.call-view aside:not(.call-left-menu):not(.grid-view):not(.invite-modal-participants) > section {
		padding-right: 0;
		padding-left: 100px;
	}

	.list-group li a {
		padding: var(--spacing-s) var(--spacing-m);
		font-size: 13px;
	}

	.list-group li a i {
		font-size: 14px;
		margin-right: var(--spacing-s);
	}

	.incoming-call-text {
		font-size: 18px;
	}

	.call-view aside:not(.call-left-menu):not(.grid-view) {
		z-index: 100;
	}

	.call-button,
	.call-dropdown-btn > a {
		padding: 10px;
	}

	.call-view:not(.initiator) .participant .dropdown {
		display: none;
	}

	.call-view__footer .dropdown {
		padding: 0;
	}

	.call-view__footer .dropdown.top .dropdown__items {
		right: -7px;
		bottom: calc(100% + 15px);
	}

	.call-view__footer .dropdown.dropdown--open::before,
	.call-view__footer .dropdown.dropdown--open::after {
		display: none;
	}

	.call-view__footer > div:nth-child(2) > div.tv-controls-dropdown {
		display: none;
	}

	.call-view__footer > div:nth-child(2),
	.call-view:hover .call-view__footer > div:nth-child(2) > div:not(.more-dropdown) {
		opacity: 1;
	}

	.call-view__footer > div:nth-child(2) > div:not(.more-dropdown) {
		opacity: 0;
	}

	.more-dropdown {
		position: fixed;
		left: 100px;
		bottom: 35px;
		z-index: 1;
	}

	.call-view.hide-controls footer .more-dropdown {
		opacity: 1;
		visibility: visible;
	}

	.patient-view .more-dropdown {
		left: var(--spacing-xl);
	}

	.more-dropdown[data-tooltip]::before,
	.more-dropdown[data-tooltip]::after,
	.dark-theme .more-dropdown[data-tooltip]::before,
	.dark-theme .more-dropdown[data-tooltip]::after {
		opacity: 0;
		background: none;
	}

	.more-dropdown a {
		background: var(--dark-theme-gray-2);
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		margin-bottom: 5px;
	}

	.more-dropdown .tv-controls-dropdown > a {
		width: 100%;
		justify-content: flex-start;
		padding: var(--spacing-l);
		box-shadow: none;
	}

	.more-dropdown .tv-controls-dropdown .list-group {
		background: none;
		box-shadow: none;
	}

	.more-dropdown .tv-controls-dropdown .list-group li a {
		width: 100%;
		justify-content: flex-start;
		padding: var(--spacing-l);
		box-shadow: none;
		background: none;
		width: auto;
		height: auto;
	}

	.more-dropdown .tv-controls-dropdown:after,
	.more-dropdown .tv-controls-dropdown:before {
		content: '';
	}

	.more-dropdown .tv-controls-dropdown > a > img {
		width: 24px;
	}

	.more-dropdown > a::after {
		position: absolute;
		content: 'More';
		top: 64px;
		left: 50%;
		transform: translate(-50%);
		color: var(--gray-0);
		font-size: 12px;
	}

	.more-dropdown .tv-controls-dropdown > a::after {
		position: absolute;
		content: 'TV Controls';
		top: 20px;
		left: 88px;
		transform: translate(-50%);
		color: var(--gray-0);
		font-size: 14px;
	}

	.more-dropdown.dropdown .dropdown__items {
		width: 100vw;
		height: 100vh;
		padding-left: var(--spacing-l) !important;
		transition: 0s;
		transform: none;
	}

	.more-dropdown .dropdown-inner-header {
		display: block;
		background: var(--dark-theme-gray-4);
	}

	.more-dropdown .dropdown-inner-header i {
		position: absolute;
		top: var(--spacing-l);
		right: var(--spacing-l);
		color: var(--dark-theme-gray-0);
		font-size: 24px;
	}

	.dropdown-inner-header > p {
		font-size: 28px;
		text-align: left;
		margin-top: var(--spacing-ssl);
	}

	.dropdown.tv-controls-dropdown:not(.dropdown--open) > .dropdown__items {
		display: none;
	}

	.dropdown.more-dropdown.dropdown--open > .dropdown__items,
	.dropdown.tv-controls-dropdown.dropdown--open > .dropdown__items {
		position: fixed;
		width: 100%;
		right: 0;
		top: 0;
		bottom: 120px;
		background: var(--dark-theme-gray-4);
		display: block;
		height: auto;
	}

	.dropdown.tv-controls-dropdown.dropdown--open > .dropdown__items {
		z-index: 2;
		bottom: 0;
	}

	.dropdown.more-dropdown.dropdown--open > a {
		background: var(--gray-5);
	}

	.call-view:hover .call-view .call-view__footer > div:nth-child(1) h3 {
		font-size: 14px;
	}

	.call-correlation-info span .copy-to-clipboard i {
		font-size: 12px;
	}

	.call-view__footer > div:nth-child(1) p {
		padding-top: 2px;
	}

	.grid-view .participant main footer p {
		font-size: 13px;
	}

	.invite-people {
		z-index: 2000;
		overflow: auto;
	}

	.modal.confirmation-modal {
		z-index: 2000;
	}

	.invite-people .modal__wrapper {
		margin: auto;
		max-width: 100%;
		min-width: auto;
	}

	.modal.invite-people .modal__wrapper,
	.modal.invite-people .modal__content {
		max-height: 100%;
	}

	.modal.invite-people.cam-settings-modal .modal__wrapper {
		margin-top: 0;
	}

	.modal.cam-settings-modal .modal__content > div > div:last-of-type {
		flex-direction: column;
	}

	.modal.cam-settings-modal .modal__content ul.tabs {
		padding: 0;
		height: auto;
	}

	.modal.invite-people.cam-settings-modal .modal__wrapper {
		height: 100vh;
	}

	.participant {
		width: 140px;
	}

	.participant main video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.toggle-right-column {
		right: 110px;
	}

	.call-view aside:not(.call-left-menu):not(.grid-view) .profile-picture-wrapper {
		width: 36px;
		height: 36px;
	}

	.call-view .invite-people aside:not(.call-left-menu):not(.grid-view) .profile-picture-wrapper {
		width: 24px;
		height: 24px;
	}

	.patient-audio-call-logo .profile-picture-wrapper {
		width: 80px;
		height: 80px;
	}

	.participant header p {
		font-size: 13px;
	}

	.grid-view {
		overflow: auto;
	}

	.call-view .grid-view {
		height: calc(100vh - 120px);
		width: 100%;
		left: 0;
	}

	.grid-view > section {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 10px;
		margin: auto;
		padding: var(--spacing-m) 0;
	}

	.grid-view .participant-wrapper video {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.grid-view .participant-wrapper {
		max-height: none;
		max-width: none;
		width: calc(50vw - 20px);
		height: calc(50vw - 20px);
		border-radius: 3px;
		overflow: hidden;
		background: transparent;
	}

	.grid-view .participant-wrapper.pinned-participant {
		grid-column: 1/3;
		grid-row-start: 1;
		width: calc(100vw - 30px);
		height: calc(9 / 16 * 100vw);
	}

	.grid-view .participant .mic_off-icon {
		padding: 2px;
		font-size: 12px;
		margin-right: var(--spacing-s);
	}

	.grid-view .participant .list-group li a > span {
		display: none;
	}

	.call-view .call-view__footer {
		display: flex !important;
		justify-content: center;
		position: fixed;
		z-index: 500;
		bottom: 120px;
	}

	.call-view__footer--desc {
		overflow: hidden;
		width: 100%;
		position: fixed;
		padding: var(--spacing-xl);
		top: 0;
		left: 0;
		bottom: auto;
		right: auto;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.65), hsla(0, 0%, 0%, 0));
		z-index: 300;
		transition: 0.15s;
		opacity: 0;
		visibility: hidden;
	}

	.call-view__footer--desc h3 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		bottom: 225px;
		left: 50%;
	}

	.ptz-is-active .call-view__footer:not(.footer-grid-view) .call-view__footer--desc h3 {
		bottom: 240px;
	}

	.call-view__footer--desc p {
		left: 50%;
	}

	.call-view__footer .call-dropdown {
		padding: 0;
	}

	.call-view aside.call-left-menu {
		bottom: 0;
		top: auto;
		height: 120px;
		width: 100%;
		position: fixed;
	}

	.call-view aside.call-left-menu .left-navigation {
		width: 100%;
		text-align: left;
		flex-direction: row;
	}

	.modal.invite-people:not(.cam-settings-modal) {
		width: 100%;
		left: 0;
		bottom: 120px;
		top: 0;
		height: auto;
	}

	.modal.invite-people .modal__wrapper {
		width: 100%;
	}

	.main-view > section {
		padding: 0 var(--spacing-l);
	}

	.main-view .hello-properties li {
		margin-right: 0;
	}

	.main-layout-wrapper {
		display: flex !important;
		flex-direction: column;
	}

	.left-navigation {
		text-align: left;
		flex-direction: row;
		padding: var(--spacing-l);
		position: fixed;
		z-index: 2;
		bottom: 0;
		width: 100%;
	}

	.main-layout-wrapper > .app-wrapper > div,
	.main-layout-wrapper > .app-wrapper > div .call-logs-view > div {
		position: relative;
		grid-template-columns: 1fr !important;
	}

	.main-layout-wrapper > .app-wrapper > div .call-logs-view > div .hello-list {
		display: none;
	}

	.main-layout-wrapper .app-wrapper .hello-list {
		position: absolute;
		width: 100%;
		height: calc(100vh - 120px);
		z-index: 1;
		max-height: -webkit-fill-available;
	}

	.main-layout-wrapper .collapsed-second-column .hello-list {
		z-index: 0;
	}

	.main-view {
		flex-direction: column;
		height: calc(100vh - 120px);
	}

	.room {
		width: 100%;
	}

	.header {
		padding: var(--spacing-l) var(--spacing-s);
		margin: 0;
	}

	.header .logo {
		display: none;
	}

	.header > div:last-of-type {
		margin-left: 0;
	}

	.organization > h3,
	.users > h3 {
		display: flex;
		flex-wrap: wrap;
	}

	.organization > h3 > span,
	.users > h3 > span {
		width: 100%;
		margin-bottom: 30px;
	}

	.left-navigation li {
		margin-right: var(--spacing-l);
		margin-bottom: 0;
	}

	.collapsed-second-column aside {
		padding: initial;
	}

	.header .dropdown.bottom .dropdown__items {
		left: 0;
		right: auto;
	}

	.collapse-second-column {
		top: var(--spacing-l);
	}

	.room .hello-properties {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.hs-modal-wrapper.modal .modal__wrapper,
	.dark-theme .hs-modal-wrapper.modal .modal__wrapper {
		width: 100%;
		left: 0;
		height: calc(100vh - 120px);
	}

	.patient-view-control {
		position: fixed;
		bottom: 0;
		top: auto;
		left: 0 !important;
		width: 100%;
		z-index: 9999;
	}

	.call-view .bookmarks-wrapper {
		display: none;
	}

	.tilt__zoom {
		justify-content: center;
		right: 0;
	}

	.tilt__zoom > input,
	.dark-theme .tilt__zoom input {
		height: 2px;
		width: 150px;
	}

	.call-view .tilt_figure {
		padding: 15px;
	}

	.call-view .toggle-feed {
		left: 50%;
		transform: translate(-50%);
	}

	.call-view .tilt_container {
		width: 100%;
		background: rgba(52, 58, 64, 1);
		border-radius: 0 !important;
		align-items: center;
		padding: 35px 20px;
	}

	.privacy-buttons-enabled > main > .alert.top,
	.privacy-buttons-enabled .main-multiple-participants > .alert.top {
		width: 100%;
		max-width: initial;
		margin: 0;
		border-radius: 0;
		top: 0;
	}

	.call-view .call-view-video.call-view-without-video {
		width: 100%;
		margin-left: 0;
	}

	.privacy-buttons-enabled.call-view aside:not(.call-left-menu):not(.invite-modal-participants):not(.grid-view) {
		top: var(--spacing-sssl);
	}

	.tilt_zoom_reset {
		left: calc(50% - 1px);
	}

	.reset-camera {
		width: calc(100% - var(--spacing-ssl));
		justify-content: center;
	}
}

@media only screen and (min-aspect-ratio: 13/9) and (max-device-width: 1024px) and (orientation: landscape) {
	.call-view {
		max-height: -webkit-fill-available;
	}

	.call-view aside.call-left-menu .left-navigation {
		width: 90px;
	}

	.call-view .grid-view {
		height: 100vh;
		width: calc(100% - 90px);
		left: 90px;
	}

	.call-view:not(.tablet-call-view) .grid-view .participant-wrapper {
		width: calc(50vw - 45px);
		height: calc(50vw - 45px);
	}

	.call-view:not(.tablet-call-view) aside.call-left-menu {
		bottom: auto;
		top: 0;
		height: 100vh;
		width: auto;
	}

	.call-view:not(.tablet-call-view) .call-view__footer {
		bottom: 0;
	}

	.call-view:not(.tablet-call-view) .call-view__footer--desc h3 {
		bottom: 110px;
	}

	.call-view:not(.tablet-call-view) aside.call-left-menu .left-navigation li {
		justify-content: flex-start;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .more-dropdown {
		position: fixed;
		left: var(--spacing-l);
		top: 120px;
	}

	.call-view:not(.tablet-call-view) .left-navigation {
		padding: var(--spacing-l);
	}

	.call-view:not(.tablet-call-view) .left-navigation li {
		padding: 0;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .dropdown.more-dropdown.dropdown--open > .dropdown__items {
		flex-wrap: wrap;
		display: flex;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .dropdown.more-dropdown.dropdown--open > .dropdown__items,
	.call-view:not(.tablet-call-view) .call-view__footer .dropdown.tv-controls-dropdown.dropdown--open > .dropdown__items {
		align-content: flex-start;
		height: 100vh;
	}

	.call-dropdown-btn .dropdown__items > .dropdown-inner-header {
		flex-basis: 100%;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .call-dropdown-btn .dropdown__items > div:not(.dropdown-inner-header) {
		flex-basis: 32%;
		margin-right: 0;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .tv-controls-dropdown .list-group li {
		flex-basis: calc(50% - 15px);
	}

	.call-view:not(.tablet-call-view) .call-view__footer .tv-controls-dropdown .list-group {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.call-view:not(.tablet-call-view) .modal.invite-people:not(.cam-settings-modal) {
		width: calc(100% - 90px);
		left: 90px;
		height: 100vh;
	}

	.call-view:not(.tablet-call-view) .modal.invite-people.cam-settings-modal .modal__wrapper {
		height: 99vh;
	}

	.call-view:not(.tablet-call-view) .modal.invite-people .modal__wrapper {
		height: 100vh;
	}

	.call-view:not(.tablet-call-view).privacy-buttons-enabled > main > .alert.top,
	.call-view:not(.tablet-call-view).privacy-buttons-enabled .main-multiple-participants > .alert.top {
		width: calc(100% - 90px);
		margin-left: 45px;
	}

	.call-view:not(.tablet-call-view) .modal.invite-people.cam-settings-modal {
		left: 90px;
		width: calc(100% - 90px);
	}

	.guest-join > div > div {
		bottom: auto;
	}

	.call-view:not(.tablet-call-view) .call-view__footer .call-view__footer--desc p,
	.call-view:not(.tablet-call-view) .call-view__footer.footer-multiple-participants .call-view__footer--desc p,
	.call-view:not(.tablet-call-view).privacy-buttons-enabled .call-view__footer:not(.footer-multiple-participants) .call-view__footer--desc p {
		top: var(--spacing-s);
		font-size: 12px;
		padding: 0 var(--spacing-s);
	}

	.call-view:not(.tablet-call-view).privacy-buttons-enabled .call-view__footer:not(.footer-multiple-participants) .call-view__footer--desc p,
	.call-view:not(.tablet-call-view).privacy-buttons-enabled .call-view__footer.footer-multiple-participants:not(.footer-grid-view) .call-view__footer--desc p {
		top: 42px;
	}

	.call-view:not(.tablet-call-view) .call-view__footer--desc h3 {
		font-size: 14px;
	}

	.participants-number > p {
		font-size: 14px;
	}

	.participants-number {
		width: 35px;
	}

	.call-view aside:not(.call-left-menu):not(.grid-view) .participant-wrapper {
		margin-left: var(--spacing-s);
	}

	.call-view .logo {
		left: calc(var(--spacing-xl) + 75px);
	}

	.call-view .logo > img {
		height: 40px;
	}
}

@media only screen and (max-width: 599px) {
	.call-view .logo.multiple-part-logo {
		margin-top: 130px;
	}

	.privacy-buttons-enabled.call-view .logo.logo.multiple-part-logo {
		margin-top: 150px;
	}

	.invite-participants-list {
		max-height: calc(100vh - 500px);
		min-height: 100px;
	}

	.modal.center .modal__wrapper {
		width: 100%;
		min-width: auto;
		height: 100vh;
	}

	.modal.center .modal__actions {
		flex-wrap: wrap;
	}

	.button.large {
		padding: 8px 12px;
		font-size: 14px;
		margin-bottom: 8px;
	}

	.privacy-buttons-enabled .main-multiple-participants > .alert.top {
		font-size: 13px;
	}

	.privacy-buttons-enabled .call-view__footer:not(.footer-multiple-participants) .call-view__footer--desc p {
		top: 170px;
	}

	.privacy-buttons-enabled aside:not(.grid-view) > section > .participant-wrapper.active-participant-wrapper {
		top: 200px;
	}
}

@media only screen and (max-width: 500px) {
	.privacy-buttons-enabled.call-view aside:not(.call-left-menu):not(.invite-modal-participants):not(.grid-view) {
		top: calc(var(--spacing-l) + var(--spacing-sssl));
	}
}

.device-config .table main > div > div:last-of-type .wrapped {
	justify-content: flex-end;
}

.device-config .table main > div > div:last-of-type .wrapped span {
	margin-right: var(--spacing-s);
}

.input-el {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: var(--spacing-sl);
}

.input-el > label {
	display: block;
	margin-bottom: var(--spacing-m);
	color: var(--gray-5);
}

.input-el > label + p {
	padding: 0;
	color: var(--gray-4);
	margin-bottom: var(--spacing-m);
}

.input-el > main {
	display: flex;
	align-items: center;
}

.input-el > main > input {
	margin: 0 var(--spacing-s) 0 var(--spacing-xl);
}

.input-el > main > label {
	color: var(--gray-5);
}

.input-el > main > input:first-of-type {
	margin-left: 0;
}

.input-el > div {
	display: flex;
	align-items: center;
}

.input-el input[type='range'] {
	width: 100%;
	margin: var(--spacing-m) var(--spacing-l) var(--spacing-m) 0;
}

.input-el small {
	color: var(--red-1);
	font-size: 14px;
	display: block;
	margin: var(--spacing-m) 0;
	font-weight: 400;
}

.patient-search-result {
	overflow-y: scroll;
	height: 200px;
}

.sip-error-message {
	font-size: 14px;
	color: var(--red-1) !important;
}
