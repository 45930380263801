html,
body,
input,
textarea,
select,
button {
	font-family: var(--font-family);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
	color: var(--gray-5);
	font-weight: 400;
	margin: 0;
}

h1 {
	font-size: 2.44em;
	line-height: 1.33em;
	padding-top: 0.333em;
	margin-bottom: 0.333em;
}

h2 {
	font-size: 1.94em;
	line-height: 1.68em;
	padding-top: 0.484em;
	margin-bottom: 0.355em;
}

h3 {
	font-size: 1.56em;
	line-height: 1.04em;
	padding-top: 0.2em;
	margin-bottom: 0.84em;
}

h4 {
	font-size: 1.25em;
	line-height: 1.3em;
	padding-top: 0.3em;
	margin-bottom: 1em;
}

p {
	font-size: 1em;
	line-height: 1.63em;
	padding-top: 0.5em;
	margin-bottom: 1.13em;
	font-weight: 400;
}
