.hidden {
	display: none !important;
}
.padding-t-0 {
	padding-top: 0 !important;
}
.h-full {
	height: 100%;
}
.--red-color {
	color: var(--red-1) !important;
}
.--green-color {
	color: var(--green-1) !important;
}
.--blue-color {
	color: var(--blue-2) !important;
}
